import ApiClient from "Store/client";

export const fetchTestsByStudentIdApi = (student_id: string) =>
  ApiClient(`api/students/${student_id}/student_tests?all=1`);

export const fetchTestsByStudentIdScoredApi = (student_id: string, parameters: string) =>
  ApiClient(`api/students/${student_id}/student_tests?scored=1${parameters}`);

export const fetchTestByTestIdApi = (student_id: string, test_id: string) =>
  ApiClient(`api/students/${student_id}/student_tests/${test_id}`);

// export const fetchStudentTestSectionsApi = (
//   student_id: string,
//   student_test_id: string,
//   courseId: string
// ) =>
//   fetch(`${API_URL}/api/students/${student_id}/student_tests/${student_test_id}/sections/`, {
//     headers: {
//       ...defaultHeaders,
//       Authorization: `Bearer ${getToken()}`,
//       "x-Course-Id": courseId
//     }
//   })
//     .then((res) => res.json())
//     .then(({ data }) => data)
//     .catch((err) => err);

export const fetchProblemsBySectionApi = (
  student_id: string,
  student_test_id: string,
  section_id: string
) =>
  ApiClient<TestProblem>(
    `api/students/${student_id}/student_tests/${student_test_id}/sections/${section_id}/problems`
  );

export const fetchStudentTestScoreApi = (student_id: string, student_test_id: string) =>
  ApiClient(`api/students/${student_id}/student_tests/${student_test_id}/score`);

export const postStudentTestScoreApi = (student_id: string, student_test_id: string) =>
  ApiClient(`api/students/${student_id}/student_tests/${student_test_id}/score`, {
    method: "POST"
  });

export const addStudentAnswerToTestApi = (body) =>
  ApiClient(`api/commands/add-student-answer-to-test`, { method: "POST", body });

export const addStudentTestQuestionFlagApi = (body) =>
  ApiClient(`api/commands/add-student-test-question-flag`, { method: "POST", body });

// export const removeStudentTestQuestionFlagApi = (body, courseId: string) =>
//   fetch(`${API_URL}/api/commands/remove-student-test-question-flag`, {
//     method: "DELETE",
//     headers: {
//       ...defaultHeaders,
//       Authorization: `Bearer ${getToken()}`,
//       "x-Course-Id": courseId
//     },
//     body: JSON.stringify(body)
//   }).catch((err) => err);

export const updateStudentTestStatusApi = (body) =>
  ApiClient(`api/commands/update-student-test-status`, { method: "PATCH", body });

export const updateStudentTestSectionStatusApi = (body) =>
  ApiClient(`api/commands/update-student-test-section-status`, { method: "PATCH", body });

export const updateStudentTestQuestionFlagStatusApi = (body) =>
  ApiClient(`api/commands/update-student-test-question-flag-status`, { method: "PATCH", body });

export const startTribyteTestApi = (body: StartTribyteTestApi) =>
  ApiClient(`api/commands/start-tribyte-test`, { method: "POST", body, type: "json" });

export const scoreTribyteTestApi = (body: ScoreTribyteTestApi) =>
  ApiClient(`api/commands/score-tribyte-test`, { method: "POST", body, type: "text" });

export const completeBlueBookTestApi = (body: ScoreBlueBookTestApi) =>
  ApiClient(`api/commands/complete-bluebook-test`, { method: "POST", body, type: "json" });

export const fetchTribyteTestSectionApi = (body: FetchTribyteTestSectionRequest) =>
  ApiClient("api/commands/review-tribyte-test-section", {
    method: "POST",
    body
  });
