import * as todosType from "./constants";

export const handleGetTodo = (): todosType.IGetTodoList => ({
  type: todosType.HANDLE_FETCH_TODO_LIST
});

export const setTodo = (payload): todosType.IGetTodoListResolved => ({
  type: todosType.SET_TODO_LIST,
  payload
});

export const handleFetchSummary = (): todosType.IHandleFetchSummary => ({
  type: todosType.HANDLE_FETCH_SUMMARY
});

export const setSummary = (summary: StudentSummary[]): todosType.IFetchSummaryResolved => ({
  type: todosType.SET_SUMMARY,
  payload: summary
});
