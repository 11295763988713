import * as worksheetTypes from "./constants";

export const fetchWorksheets = (): worksheetTypes.IHandleFetchWorksheets => ({
  type: worksheetTypes.HANDLE_FETCH_WORKSHEETS
});

export const setWorksheets = (worksheets): worksheetTypes.FetchWorksheetsResolved => ({
  type: worksheetTypes.SET_WORKSHEETS,
  payload: worksheets
});

export const handleAddAnswerToWorksheet = (
  body: AddWorksheetAnswerRequest
): worksheetTypes.IHandleAddAnswerToWorksheet => ({
  type: worksheetTypes.HANDLE_ADD_ANSWER_TO_WORKSHEET,
  body
});

export const addAnswerToWorksheetRejected = (
  payload: AnswerRejectedPayload
): worksheetTypes.IAddAnswerToWorksheetRejected => ({
  type: worksheetTypes.ADD_ANSWER_TO_WORKSHEET_REJECTED,
  payload
});

export const handleStartStudentWorksheet = (
  worksheet: Worksheet
): worksheetTypes.IHandleStartStudentWorksheet => ({
  type: worksheetTypes.HANDLE_START_STUDENT_WORKSHEET,
  worksheet
});

export const handleFetchWorksheet = (
  payload: string
): worksheetTypes.IHandleOpenDetailWorksheetReview => ({
  type: worksheetTypes.HANDLE_FETCH_WORKSHEET,
  payload
});

export const setActiveWorksheet = (worksheet: Worksheet): worksheetTypes.ISetStudentWorkSheet => ({
  type: worksheetTypes.SET_STUDENT_WORKSHEET,
  worksheet
});

export const handleCompleteStudentWorksheet = (
  worksheet: Worksheet
): worksheetTypes.IHandleCompleteStudentWorksheet => ({
  type: worksheetTypes.HANDLE_COMPLETE_STUDENT_WORKSHEET,
  worksheet
});

export const handleAddStudentWorksheetFlag = (
  payload: WorksheetFlagBody
): worksheetTypes.IHandleAddStudentWorksheetProblemFlag => ({
  type: worksheetTypes.HANDLE_ADD_STUDENT_WORKSHEET_PROBLEM_FLAG,
  payload
});

export const handleAddWorksheetVideoWatchedTime = (
  payload: WorksheetVideoWatchedTimeUpdate
): worksheetTypes.IHandleAddWorksheetVideoWatchedTime => ({
  type: worksheetTypes.HANDLE_ADD_WORKSHEET_VIDEO_WATCHED_TIME,
  payload
});

export const setFromCompletedWorksheet = (payload): worksheetTypes.ISetFromCompletedWorksheet => ({
  type: worksheetTypes.SET_FROM_COMPLETED_WORKSHEET,
  payload
});
