import ApiClient from "Store/client";

export const fetchWorksheetsApi = (studentId: string) =>
  ApiClient(`api/students/${studentId}/student_worksheets?simplified=1`);

export const fetchWorksheetByIdApi = (studentId: string, worksheetId: string) =>
  ApiClient(`api/students/${studentId}/student_worksheets/${worksheetId}`);

export const updateStudentWorksheetStatusApi = (body) =>
  ApiClient(`api/commands/update-student-worksheet-status`, { method: "PATCH", body });

export const addAnswerToWorksheetApi = (body) =>
  ApiClient(`api/commands/add-student-worksheet-problem-answer`, {
    method: "POST",
    body,
    type: "text"
  });

export const addStudentWorksheetProblemFlagApi = (body) =>
  ApiClient(`api/commands/flag-student-worksheet-problem`, { method: "POST", body, type: "text" });

export const addWorksheetVideoWatchedTimeApi = (body) =>
  ApiClient(`api/commands/watch-student-worksheet-problem-video`, {
    method: "POST",
    body,
    type: "text"
  });
