import Link from "next/link";
import React from "react";
import Icon from "components/Shared/Icons/Icon/Icon";
import colors from "constants/colors";
import { genClasses } from "utils/textHelpers";

interface IProps {
  isLogged?: boolean;
  avatarURL: string;
  name?: string;
  tooltipInfo?: string;
  linkTarget?: string;
  showEditIcon?: boolean;
  /* List size avatar, the big one is default, large is for profile pages */
  small?: boolean;
  smallest?: boolean;
  large?: boolean;
  inactive?: boolean;
  type?: "student" | "instructor" | "classroom" | "location";
  className?: string;
  archived?: boolean;
}

const ICON_BY_TYPE = {
  student: "students",
  instructor: "teacher",
  classroom: "users",
  location: "marker"
};

export const Avatar: React.FC<IProps> = ({
  avatarURL,
  isLogged = true,
  name,
  linkTarget,
  showEditIcon,
  small,
  smallest,
  large,
  inactive,
  type = "student",
  className,
  archived
}) => {
  const renderAvatar = () => {
    return avatarURL ? (
      <img
        className={genClasses(`avatar-image`, {
          small: small,
          smallest: smallest,
          large: large,
          inactive: inactive,
          archived: archived
        })}
        src={avatarURL}
        alt="profile-avatar"
      />
    ) : name ? (
      getInitialsAvatar()
    ) : (
      <Icon
        icon={ICON_BY_TYPE[type] as IconType}
        className={genClasses(`avatar-icon`, {
          small: small,
          smallest: smallest,
          large: large
        })}
      />
    );
  };

  const getInitialsAvatar = () => {
    const nameParts = name
      .replace(/[^A-Z0-9`.?-]+/gi, "_")
      .split("_")
      .filter((e) => e !== "undefined");
    const initials =
      nameParts.length > 0 ? `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}` : "";

    return nameParts.length > 0 ? (
      <div
        className={genClasses("generated-avatar", {
          small: small,
          smallest: smallest,
          large: large,
          inactive: inactive,
          archived: archived
        })}
        style={{
          width: "100px",
          height: "100px",
          ...(!inactive
            ? {
                backgroundColor: type === "student" ? colors.studentBlue : colors.instructorGreen
              }
            : {})
        }}
      >
        {initials}
      </div>
    ) : (
      <Icon
        icon={ICON_BY_TYPE[type] as IconType}
        className={genClasses("avatar-icon", {
          small: small,
          smallest: smallest,
          large: large,
          inactive: inactive
        })}
      />
    );
  };

  return (
    <div className={genClasses("avatar", { inactive: inactive, className })}>
      {isLogged && (
        <Link href={linkTarget || ""}>
          <div className="avatar-wrapper-image">
            {renderAvatar()}
            {showEditIcon && (
              <div className="avatar-button-edit">
                <Icon icon="edit" className="avatar-button-edit-icon" color={colors.black} />
              </div>
            )}
            {/*{(inactive || tooltipInfo) && (*/}
            {/*  <div className={genClasses("avatar-tooltip", { small: small })}>*/}
            {/*    <InfoIcon*/}
            {/*      info={tooltipInfo || INFOS.inactiveStudentTooltip}*/}
            {/*      toolTipStyle={{ width: 300 }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        </Link>
      )}
    </div>
  );
};
