import ApiClient from "Store/client";

export const fetchSummaryApi = async (studentId) => ApiClient(`api/students/${studentId}/summary`);

// export const fetchLessonListApi = (courseId) =>
//   fetch(`${API_URL}/api/lessons`, {
//     headers: {
//       Accept: "application/json",
//       "x-Course-Id": courseId,
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${getToken()}`
//     }
//   })
//     .then((res) => res.json())
//     .catch((err) => err);

export const fetchTodosByStudentIdApi = (student_id) =>
  ApiClient(`api/students/${student_id}/todo`);
