import React, { createContext, FC, ReactElement, useState } from "react";

interface IHeaderContext {
  title: string;
  icon: IconType;
  headerTabs: Tab[];
  activeTab: string;
  isBackButton: boolean;
  disabledTab: boolean;
  hideCourseSelector?: boolean;
  additionalInformationContainer?: React.ReactElement;
  handleOnChangeHeaderValue: (values: HeaderContextValue) => void;
  handleOnSetActiveTab: (id: string) => void;
}

export const HeaderContext = createContext<IHeaderContext>({} as IHeaderContext);

interface IProps {
  children: ReactElement;
}

const HeaderProvider: FC<IProps> = ({ children }) => {
  const [header, setHeader] = useState({
    title: "",
    icon: "" as IconType,
    isBackButton: false,
    headerTabs: [],
    activeTab: "",
    disabledTab: false,
    hideCourseSelector: false,
    additionalInformationContainer: null
  });

  const handleOnChangeHeaderValue = (values: HeaderContextValue) => {
    setHeader({
      ...header,
      ...values
    });
  };
  const handleOnSetActiveTab = (id: string) => {
    setHeader({ ...header, activeTab: id });
  };
  return (
    <HeaderContext.Provider
      value={{
        title: header.title,
        icon: header.icon,
        isBackButton: header.isBackButton,
        headerTabs: header.headerTabs,
        activeTab: header.activeTab,
        disabledTab: header.disabledTab,
        hideCourseSelector: header.hideCourseSelector,
        additionalInformationContainer: header.additionalInformationContainer || null,
        handleOnChangeHeaderValue,
        handleOnSetActiveTab
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
