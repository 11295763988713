import React, { FC, useState } from "react";
import Icon from "components/Shared/Icons/Icon/Icon";
import Link from "next/link";

interface IProps {
  item: Menu;
  activeLink: string;
  brandingColor: string;
  onClick: () => void;
}

const SideNavItem: FC<IProps> = ({ item, activeLink, brandingColor, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const activeItem = activeLink === item.value;
  const content = (
    <li
      className="menu-item"
      style={{ backgroundColor: (activeItem || isHovered) && "#fff" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      id={item.value}
    >
      <Icon
        icon={item.icon}
        className="menu-icon"
        style={{ color: (activeItem || isHovered) && brandingColor }}
      />
      <p className="menu-label" style={{ color: (activeItem || isHovered) && brandingColor }}>
        {item.label}
      </p>
    </li>
  );
  return item.label === "Help" ? (
    <a
      target="_blank"
      href="https://instructor.support.myprepcourse.com"
      className="menu-item--additional"
    >
      {content}
    </a>
  ) : (
    <Link href={`/${item.value}`} key={item.value}>
      {content}
    </Link>
  );
};

export default SideNavItem;
