import { all, call } from "redux-saga/effects";
import worksheetSaga from "./worksheet/saga";
import userSaga from "./user/saga";
import dashboardSaga from "./dashboard/saga";
import testSaga from "./tests/saga";
import workbookSaga from "./lessons/saga";

export default function* rootSaga() {
  yield all([
    call(worksheetSaga),
    call(userSaga),
    call(dashboardSaga),
    call(testSaga),
    call(workbookSaga)
  ]);
}
