// student profile saga

import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import {
  changePasswordApi,
  currentLocationApi,
  fetchUsersByTokenApi,
  resetPasswordApi,
  updateStudentAddressApi,
  updateStudentApi,
  updateStudentAvatarApi,
  updateStudentShowWelcomeVideoApi,
  updateLastVersionSeenApi,
  loginApi,
  LogoutApi
} from "./api";
import {
  handleResetUserErrors,
  handleUpdateUserErrors,
  setError,
  updateStudentAddressResolved,
  updateStudentAddressRejected,
  handleUpdateUserMessage,
  setActiveUser,
  setActiveCourse,
  handleSetCourses,
  updateStudentResolved,
  setCurrentLocation,
  fetchLocation,
  updateStudentAvatarPending,
  updateStudentAvatarResolved,
  setUserIsLogged
} from "./actions";
import * as userTypes from "./constants";
import { makeSelectActiveUser } from "./selectors";
import { getToken, logout, setToken } from "./AuthService";
import { generateToastFromObject, Toast } from "utils/toast";
import { setActionSpinner } from "Store/global/actions";
import Router from "next/router";
import ERRORS from "constants/localization/error";
import { isNotEmpty } from "utils/objectsHelper";
import { setActiveCourseId } from "utils/ActiveCourseService";

export function* handleGetInitialCourse() {
  try {
    let activeUser = yield select(makeSelectActiveUser);

    let activeCourse: Course;
    const rawCourse = localStorage.getItem("activeCourseId");

    if (!rawCourse || rawCourse === "undefined") {
      activeCourse =
        activeUser?.courses && !!activeUser?.courses.length ? activeUser?.courses[0] : {};
    } else {
      activeCourse = activeUser?.courses?.find((course) => course.course_id === rawCourse);

      if (!activeCourse) {
        activeCourse =
          activeUser?.courses && !!activeUser?.courses.length ? activeUser?.courses[0] : {};
      }
    }

    return activeCourse;
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    return console.warn("Error occurred in the handleGetInitialCourse saga", error);
  }
}

function* handleChangePassword(action: userTypes.IHandleChangePassword) {
  yield put(setActionSpinner(true));
  try {
    let message = "Something went wrong changing your password. Please try again later.";
    const { error, status } = yield changePasswordApi(action.payload);
    if (error) {
      switch (status) {
        case 400:
          message = `The password reset token appears to be invalid, expired, or used.`;
          break;
        case 422:
          message = `Something went wrong validating your new password. Your token or new password might be invalid.`;
          break;
        default:
          break;
      }
      Toast.error(message);
      return console.warn("Error occurred in the handleChangePassword saga", error);
    }
    Router.push("/login");
    Toast.success(`Password reset successfully!`);
  } catch (error) {
    Toast.error(`Something went wrong changing your password. Please try again later.`);
    console.warn("Error occurred in the handleChangePassword saga", error);
  } finally {
    yield put(setActionSpinner(false));
  }
}

function* handleResetPassword(action: userTypes.IHandleRequestResetPassword) {
  try {
    let message = `Something went wrong sending a reset password email. Please try again later.`;
    const { error, status } = yield resetPasswordApi({ email: action.payload.email });
    if (error) {
      switch (status) {
        case 422:
          message = `Something went wrong with this request. Please make sure your email is included and typed correctly.`;
          break;
        case 404:
          message = `This email address cannot be found. Please try again with a valid email.`;
          break;
        default:
          break;
      }
      generateToastFromObject(message, "");
      return console.warn("Error occurred in the handleResetPassword saga");
    }
    Toast.success(`Email successfully sent. Please check your email.`);
  } catch (error) {}
}

function* handleUpdateStudentAddress(action: userTypes.IHandleUpdateStudentAddress) {
  try {
    const { error } = yield updateStudentAddressApi(action.payload);

    if (error) {
      yield put(
        updateStudentAddressRejected({
          propertyName: "updateStudentAddress",
          message: `Something went wrong updating this student. Check that the state, city, address lines, zip and phone fields are not missing.`
        })
      );

      return console.warn("Error occurred in handleUpdateStudentAddress saga", error);
    }
    const data = { ...action.payload };
    delete data.student_id;
    yield put(updateStudentResolved(data, "addresses"));
    const successMessage = "Address saved.";
    yield put(updateStudentAddressResolved(successMessage));
  } catch (error) {
    yield put(
      updateStudentAddressRejected({
        propertyName: "updateStudentAddress",
        message: `Something went wrong updating this student. Check that the state, city, address lines, zip and phone fields are not missing.`
      })
    );
    return console.warn("Error occurred in handleUpdateStudentAddress saga", error);
  }
}

function* handleUpdateStudent(action: userTypes.IHandleUpdateStudent) {
  try {
    const { error } = yield updateStudentApi(action.payload);
    if (error) {
      yield put(
        handleUpdateUserErrors(
          "updateStudent",
          `Something went wrong updating this student. Check that the first name, last name, email, and timezone fields are not missing.`
        )
      );
      yield put(handleResetUserErrors(["updateStudent"]));
      return console.warn("Error occurred in handleUpdateStudent saga", error);
    }
    const data = { ...action.payload };
    delete data.student_id;
    yield put(updateStudentResolved(data));
  } catch (error) {
    yield put(
      handleUpdateUserErrors(
        "updateStudent",
        `Something went wrong updating this student's address.`
      )
    );
    yield put(handleResetUserErrors(["updateStudent"]));
    return console.warn("Error occurred in handleUpdateStudent saga", error);
  }
}

function* handleUpdateStudentAvatar(action: userTypes.IHandleUpdateStudentAvatar) {
  yield put(updateStudentAvatarPending());
  try {
    const { error } = yield updateStudentAvatarApi(action.body);
    if (error) {
      yield put(
        handleUpdateUserErrors(
          "avatarUpdateErr",
          "Something went wrong in updating your avatar. Please try again later."
        )
      );
      yield put(handleResetUserErrors(["avatarUpdateErr"]));
      return console.warn("Error occurred in the handleUpdateStudentAvatar saga", error);
    }
    yield delay(1500);
    const { data: activeUser } = yield fetchUsersByTokenApi(); // no course id needed

    yield put(updateStudentAvatarResolved(activeUser.avatar));
    Toast.success(`Avatar successfully changed.`);
  } catch (error) {
    yield put(
      handleUpdateUserErrors(
        "avatarUpdateErr",
        "Something went wrong in updating your avatar. Please try again later."
      )
    );
    yield put(handleResetUserErrors(["avatarUpdateErr"]));
    console.warn("Error occurred in the handleUpdateStudentAvatar saga", error);
  }
}

function* handleFetchLocation() {
  try {
    const { data, error } = yield currentLocationApi(); // no course id needed
    if (error) {
      return console.warn("Error occurred in the handleFetchLocation saga", error);
    }

    // Check if the user's window location matches the current location. If not redirect to Bryan's alternate url
    if (
      data?.urls?.domain !== window?.location?.hostname &&
      window.location.hostname !== "localhost"
    ) {
      return window.location.replace("https://notification.mysatcourse.com/");
    }

    yield put(setCurrentLocation(data));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleFetchLocation saga", error);
  }
}

function* handleUpdateStudentFlags(action: userTypes.IHandleUpdateStudentFlags) {
  try {
    const { error } = yield updateStudentShowWelcomeVideoApi(action.data);
    if (error) {
      yield put(
        handleUpdateUserErrors(
          "updateShowWelcomeVideo",
          "⚠️Something went wrong disabling the welcome video. Try again on your next login."
        )
      );
      return console.warn("Error occurred in the handleUpdateStudentFlags saga", error);
    }
  } catch (error) {
    yield put(
      handleUpdateUserErrors(
        "updateShowWelcomeVideo",
        "⚠️Something went wrong disabling the welcome video. Try again on your next login."
      )
    );
    console.warn("Error occurred in the handleUpdateStudentFlags saga", error);
  }
}

function* handleUpdateLastVersionSeen(action) {
  try {
    yield updateLastVersionSeenApi(action.payload);

    const user = yield call(getActiveUser);
    const updatedUser = {
      ...user,
      last_version_seen: action.payload.last_version_seen
    };
    updateStudentResolved(updatedUser);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  }
}

function* handleFetchActiveUser() {
  try {
    yield put(fetchLocation());

    let activeUser: User = yield select(makeSelectActiveUser);
    const token: string = yield getToken();
    if (!isNotEmpty(activeUser) && token) {
      const { data } = yield fetchUsersByTokenApi(); // no course id needed
      activeUser = { ...data };
    }
    if (!activeUser) return yield Router.push("/login");

    yield put(setActiveUser(activeUser));

    yield put(handleSetCourses(activeUser?.courses || []));

    // Check if active course exists in local storage
    const activeCourse: Course = yield call(handleGetInitialCourse);
    setActiveCourseId(activeCourse.course_id);

    yield put(setActiveCourse(activeCourse));
    yield put(handleUpdateUserMessage(null));
  } catch (error) {
    console.warn("Error occurred in the handleFetchActiveUser saga", error);
    yield put(setError(error.message ? error.message : "Something went wrong."));
  }
}

export function* getActiveUser() {
  let activeUser: User = yield select(makeSelectActiveUser);
  if (!activeUser) {
    yield call(handleFetchActiveUser);
    activeUser = yield select(makeSelectActiveUser);
  }
  return activeUser;
}

function* loginSaga(action) {
  yield put(setActionSpinner(true));
  try {
    const { data, error } = yield loginApi(action.payload);
    if (error) {
      generateToastFromObject(error, "");
    } else {
      if (data && data.intended_origin && data.user) {
        const { token, expires_at, intended_origin, user } = data;
        window?.location?.replace(
          `${intended_origin}${
            user.type !== "STUDENT" ? "/admin" : ""
          }/dashboard?expires_at=${expires_at}auth=${token}`
        );
      } else if (data.token && data.expires_at) {
        if (data.user && data.user.type === "STUDENT") {
          setToken(data.token, data.expires_at, "student");
          yield call(handleFetchActiveUser);
          yield put(setUserIsLogged(true));
          yield Router.push("/dashboard");
        } else {
          setToken(data.token, data.expires_at, "admin");
          window?.location?.replace(`${window.location.origin}/admin/`);
        }
      }
    }
  } catch (error) {
    generateToastFromObject(error, "");
  } finally {
    yield put(setActionSpinner(false));
  }
}

function* logoutSaga() {
  yield put(setActionSpinner(true));
  try {
    if (localStorage.getItem("studentToken")) {
      const { error, status } = yield LogoutApi();
      if (error && status !== 401) {
        Toast.error(ERRORS.logoutSaga, error);
        return;
      }
    }
    yield Router.replace("/login");
    logout();
    yield put(setActiveUser(null));
    yield put(setUserIsLogged(false));
  } catch (error) {
  } finally {
    yield put(setActionSpinner(false));
  }
}

export default function* userSaga() {
  yield takeLatest(userTypes.HANDLE_FETCH_ACTIVE_USER, handleFetchActiveUser);
  yield takeLatest(userTypes.HANDLE_UPDATE_STUDENT_FLAGS, handleUpdateStudentFlags);
  yield takeLatest(userTypes.HANDLE_UPDATE_STUDENT_ADDRESS, handleUpdateStudentAddress);
  yield takeLatest(userTypes.HANDLE_UPDATE_STUDENT_AVATAR, handleUpdateStudentAvatar);
  yield takeLatest(userTypes.HANDLE_UPDATE_STUDENT, handleUpdateStudent);
  yield takeLatest(userTypes.HANDLE_FETCH_LOCATION, handleFetchLocation);
  yield takeLatest(userTypes.HANDLE_CHANGE_PASSWORD, handleChangePassword);
  yield takeLatest(userTypes.HANDLE_REQUEST_RESET_PASSWORD, handleResetPassword);
  yield takeLatest(userTypes.HANDLE_UPDATE_LAST_VERSION_SEEN, handleUpdateLastVersionSeen);
  yield takeLatest(userTypes.HANDLE_LOGIN, loginSaga);
  yield takeLatest(userTypes.HANDLE_LOGOUT, logoutSaga);
}
