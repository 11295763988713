import Icon from "components/Shared/Icons/Icon/Icon";
import { setContrast } from "constants/colors";
import { menuItems } from "constants/menuItems";
import Router, { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout } from "Store/user/actions";
import {
  makeSelectActiveUser,
  makeSelectLocation,
  makeSelectUserIsLogged
} from "Store/user/selectors";
import { Avatar } from "components/Shared/Avatar/Avatar";
import SideMenu from "components/Shared/SideMenu/SideMenu";
import config from "package.json";
import CourseSelectDropDown from "../Navbar/Courses/CourseSelectDropDown/CourseSelectDropDown";
import { useBreakpointAction } from "utils/hooks/useBreakpointAction";

const SideNav = () => {
  const [activeLink, setActiveLink] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(false);

  const activeUser = useSelector(makeSelectActiveUser);
  const isLogged = useSelector(makeSelectUserIsLogged);
  const location = useSelector(makeSelectLocation);
  const [textColor, setTextColor] = useState("white");
  const [colorScheme, setColorScheme] = useState("light");
  const [showCourseSelector, setShowCourseSelector] = useState(false);

  const [avatar, setAvatar] = useState(true);
  const router = useRouter();

  useBreakpointAction(576, setShowCourseSelector);

  const { branding } = location;
  const dispatch = useDispatch();

  const brandingColor = branding?.color1;

  const handleMenuItemClick = (): void => setIsShow(false);

  useEffect(() => {
    const pathLink = router.route.split("/")[1];
    if (!activeLink) {
      setActiveLink("dashboard");
    } else if (activeLink !== pathLink) {
      setActiveLink(pathLink);
    }
  }, [router]);

  useEffect(() => {
    const initialPathLink = menuItems.find((link) => link.value === Router.pathname.split("/")[1]);
    if (initialPathLink) {
      setActiveLink(initialPathLink.value);
    }
    // setTextColor(setContrast(branding?.color1));
    const avatarLocal = localStorage.getItem("impersonated");

    setAvatar(Boolean(avatarLocal));
  }, []);

  const handleSideNavPosition = () => {
    return isShow ? "opened" : "closed";
  };

  const handleSwitchMenu = () => {
    setIsShow(!isShow);
  };

  const handleSetActiveLink = (item: string) => {
    setActiveLink(item);
  };

  const getImpersonateAvatarProps = () => {
    const init = {
      avatarURL: null,
      name: null
    };
    const avatar = localStorage.getItem("impersonated");
    const avatarName = avatar?.split("impersonate-name-")[1];

    if (avatarName) {
      init.name = avatarName.split("%20").join(" ");
    } else {
      init.avatarURL = avatar;
    }

    return init;
  };

  useEffect(() => {
    if (location?.branding?.color1) {
      setColorScheme(setContrast(location?.branding?.color1));
    }
  }, [location]);

  return (
    <>
      <aside
        id="slide-out"
        className={`sidenav ${handleSideNavPosition()}`}
        style={{ background: "none", backgroundColor: brandingColor }}
      >
        <div className="sidenav-holder sidenav-container" style={{ color: textColor }}>
          <div>
            {showCourseSelector && <CourseSelectDropDown />}
            <div className="avatars-container">
              <Avatar
                avatarURL={activeUser?.avatar}
                linkTarget="/profile"
                isLogged={isLogged}
                showEditIcon
                name={`${activeUser?.first_name} ${activeUser?.last_name}`}
                className={avatar ? "impersonated-avatar" : ""}
              />
              {avatar && <Avatar className="impersonate-avatar" {...getImpersonateAvatarProps()} />}
            </div>
            <div className="sidenav-user">
              {isLogged && activeUser
                ? `${activeUser.first_name} ${activeUser.last_name}`
                : "Loading..."}
            </div>
            <div className="sidenav-role">Student</div>
            <SideMenu
              menu={menuItems}
              onHandleMenuItemClick={handleMenuItemClick}
              activeLink={activeLink}
              brandingColor={location?.branding?.color1}
            />
            <div className="sidenav-logout-container" onClick={() => dispatch(handleLogout())}>
              <Icon icon="logout" className="icon" color={textColor} />
              Logout
            </div>
          </div>
          <div className="sidenav-logo">
            <img
              style={{ maxWidth: "204px", objectFit: "contain", padding: "0 10px" }}
              src={branding && branding.black_logo}
              alt={`${location.name} Logo`}
            />
            <div className="sidebar-version">version (S){config.version}</div>
          </div>
        </div>
      </aside>
      <div className={`mobile-menu ${isShow && "transition"}`}>
        <Icon
          icon={isShow ? "close" : "bar"}
          onClick={handleSwitchMenu}
          className={`menu-icon ${isShow && "transition"}`}
          color={textColor}
        />
      </div>
    </>
  );
};

export default SideNav;
