export const makeSelectStudentSubjects = (state: AppState) => state.workBookLessonReducer.subjects;

export const makeSelectStudentUnits = (state: AppState) => state.workBookLessonReducer.studentUnits;

export const makeSelectStudentLesson = (state: AppState) =>
  state.workBookLessonReducer.studentLesson;

export const makeSelectStudentSection = (state: AppState) =>
  state.workBookLessonReducer.studentSection;

export const makeSelectLessonSectionScores = (state: AppState) =>
  state.workBookLessonReducer.sectionScores;

export const makeSelectReturnToPage = (state: AppState) => state.workBookLessonReducer.returnToPage;
export const makeSelectRoadmap = (state: AppState) => state.workBookLessonReducer.roadmap;
export const makeSelectWorkbookAnswerError = (state: AppState) =>
  state.workBookLessonReducer.addAnswerToWorkbookError;
