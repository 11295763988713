import React, { FC, CSSProperties } from "react";
import ReactLoading from "react-loading";

import colors from "constants/colors";

interface IProps {
  showSpinner: boolean;
  style?: CSSProperties;
  className?: string;
  color?: string;
}

const Spinner: FC<IProps> = ({ showSpinner, style, className, color }) => {
  return (
    showSpinner && (
      <div className={`local-spinner ${className || ""}`} style={style}>
        <ReactLoading type="spin" color={color || colors.grey} height={50} width={50} />
      </div>
    )
  );
};

export default Spinner;
