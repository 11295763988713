import * as testTypes from "./constants";
import {HANDLE_SCORE_BLUEBOOK_TEST, IHandleScoreBlueBookTest} from "./constants";

// Saga Watcher Action
export const handleFetchStudentTests = (): testTypes.IHandleFetchTestsByStudentId => ({
  type: testTypes.HANDLE_FETCH_TESTS_BY_STUDENT_ID
});

export const handleStartStudentTest = (
  payload: Partial<Test>
): testTypes.IHandleStartStudentTest => ({
  type: testTypes.HANDLE_START_STUDENT_TEST,
  payload
});

export const handleCompleteStudentTest = (payload: Test): testTypes.IHandleCompleteStudentTest => ({
  type: testTypes.HANDLE_COMPLETE_STUDENT_TEST,
  payload
});

export const handleStartStudentTestSection = (
  studentTest,
  studentTestSection
): testTypes.IHandleStartStudentTestSection => ({
  type: testTypes.HANDLE_START_STUDENT_TEST_SECTION,
  studentTest,
  studentTestSection
});

export const handleCompleteStudentTestSection = (
  studentTestSection
): testTypes.IHandleCompleteStudentTestSection => ({
  type: testTypes.HANDLE_COMPLETE_STUDENT_TEST_SECTION,
  studentTestSection
});

export const handleAddStudentAnswerToTest = (
  payload: TestAnswerBody
): testTypes.IHandleAddStudentAnswerToTest => ({
  type: testTypes.HANDLE_ADD_STUDENT_ANSWER_TO_TEST,
  payload
});

export const addStudentAnswerToTestRejected = (
  payload: AnswerRejectedPayload
): testTypes.IAddAnswerToTestRejected => ({
  type: testTypes.ADD_STUDENT_ANSWER_TO_TEST_REJECTED,
  payload
});

export const handleAddStudentTestQuestionFlag = (
  payload: TestFlagBody
): testTypes.IHandleAddStudentTestQuestionFlag => ({
  type: testTypes.HANDLE_ADD_STUDENT_TEST_QUESTION_FLAG,
  payload
});

export const handleUpdateStudentTestQuestionFlag = (
  postBody: TestFlagBody
): testTypes.IHandleUpdateStudentTestQuestionFlag => ({
  type: testTypes.HANDLE_UPDATE_STUDENT_TEST_QUESTION_FLAG,
  postBody
});

export const handleGetTestByTestId = (payload: string): testTypes.IHandleFetchTestByTestId => ({
  type: testTypes.HANDLE_FETCH_TEST_BY_TEST_ID,
  payload
});

export const handleGetProblemsByStudentTestSectionId = (
  studentTestId: string,
  testSectionId: string
): testTypes.IHandleFetchProblemsByStudentTestSectionId => ({
  type: testTypes.HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID,
  studentTestId,
  testSectionId
});

export const getProblemsByStudentTestSectionIdRejected = (
  payload: Error
): testTypes.IFetchProblemsByStudentTestSectionIdRejected => ({
  type: testTypes.FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID_REJECTED,
  payload
});

export const handleGetProblemsBySectionId = ({
  studentTestId,
  sectionId
}): testTypes.IHandleFetchStudentAnswersByStudentTestId => ({
  type: testTypes.HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID,
  studentTestId,
  sectionId
});

export const handleGetSectionBySectionId = (
  studentTestId,
  sectionId
): testTypes.IHandleFetchSectionBySectionId => ({
  type: testTypes.HANDLE_FETCH_SECTION_BY_SECTION_ID,
  studentTestId,
  sectionId
});

export const handleOpenDetailTestView = (
  payload: string
): testTypes.IHandleOpenDetailTestReview => ({
  type: testTypes.HANDLE_OPEN_DETAIL_TEST_REVIEW,
  payload
});

export const closeDetailTestView = (): testTypes.ICloseDetailTestReview => ({
  type: testTypes.CLOSE_DETAIL_TEST_REVIEW
});

export const handleGetStudentTestScores = (
  studentTestId
): testTypes.IHandleFetchStudentTestScores => ({
  type: testTypes.HANDLE_FETCH_STUDENT_TEST_SCORES,
  studentTestId
});

export const sendTestError = ({
  propertyName,
  message
}: ErrorMessage): testTypes.ISendTestError => ({
  type: testTypes.SEND_TEST_ERROR,
  propertyName,
  message
});

export const resetTestError = (messages) => ({
  type: testTypes.RESET_TEST_ERROR,
  messages
});

export const handleGetScoredTests = (): testTypes.IHandleGetScoredTests => ({
  type: testTypes.HANDLE_GET_SCORED_TESTS
});

export const getScoredTestsRejected = (error: Error): testTypes.IGetScoredTestsRejected => ({
  type: testTypes.GET_SCORED_TESTS_REJECTED,
  payload: error
});

export const getScoredTestsPending = (): testTypes.IGetScoredTestsPending => ({
  type: testTypes.GET_SCORED_TESTS_PENDING
});

export const getScoredTestsResolved = (payload: Test[]): testTypes.ISetScoredTests => ({
  type: testTypes.GET_SCORED_TESTS_RESOLVED,
  payload
});

export const setStudentTests = (payload: Test[]): testTypes.ISetStudentTests => ({
  type: testTypes.SET_STUDENT_TESTS,
  payload
});

export const setStudentActiveTest = (payload: Test): testTypes.ISetStudentActiveTest => ({
  type: testTypes.SET_STUDENT_ACTIVE_TEST,
  payload
});

export const completeStudentTestUpdate = (
  payload: TestsListByStatus
): testTypes.ICompleteStudentTestUpdate => ({
  type: testTypes.COMPLETE_STUDENT_TEST_UPDATE,
  payload
});

export const setStudentActiveTestSection = (
  payload: TestSection
): testTypes.ISetStudentActiveTestSection => ({
  type: testTypes.SET_STUDENT_ACTIVE_TEST_SECTION,
  payload
});

export const setTestProblems = (payload: TestSection): testTypes.ISetTestProblems => ({
  type: testTypes.SET_TEST_PROBLEMS,
  payload
});

export const fetchStudentTestScoresResolved = (
  payload: TestScores
): testTypes.IFetchStudentTestScoresResolved => ({
  type: testTypes.FETCH_STUDENT_TEST_SCORES_RESOLVED,
  payload
});

export const fetchTestByTestIdResolved = (payload: Test): testTypes.IFetchTestByTestIdResolved => ({
  type: testTypes.FETCH_TEST_BY_TEST_ID_RESOLVED,
  payload
});

export const setScoredTestPagination = (
  payload: Partial<Pagination>
): testTypes.ISetScoredTestPagination => ({
  type: testTypes.SET_SCORED_TESTS_PAGINATION,
  payload
});

export const setPagination = (
  payload: Pagination,
  paginationType: TemplateType
): testTypes.ISetPagination => ({
  type: testTypes.SET_PAGINATION,
  payload,
  paginationType
});

export const setFetchProblemsSpinner = (payload: boolean): testTypes.ISetFetchProblemsSpinner => ({
  type: testTypes.SET_FETCH_PROBLEMS_SPINNER,
  payload
});

export const handleStartTribyteTest = (test: Test): testTypes.IHandleStartTribyteTest => ({
  type: testTypes.HANDLE_START_TRIBYTE_TEST,
  payload: { test }
});

export const handleStartBlueBookTest = (test: Test): testTypes.IHandleStartBlueBookTest => ({
  type: testTypes.HANDLE_START_BLUEBOOK_TEST,
  payload: { test }
});

export const handleScoreTribyteTest = (): testTypes.IHandleScoreTribyteTest => ({
  type: testTypes.HANDLE_SCORE_TRIBYTE_TEST
});

export const handleScoreBlueBookTest = (test: Test, subjects: { score: number; name: string }[], answers: { question; subject; correct; answer; status }[]): IHandleScoreBlueBookTest => ({
  type: testTypes.HANDLE_SCORE_BLUEBOOK_TEST,
  payload: { test, subjects, answers }
});

export const handleFetchTribyteTestSection = (
  test: Test
): testTypes.IHandleFEtchTribyteTestSection => ({
  type: testTypes.HANDLE_FETCH_TRIBYTE_TEST_SECTION,
  payload: {
    test
  }
});

export const setTribyteTestSectionUrl = (
  url: string,
  sectionId: string
): testTypes.ISetTribyteTestSectionUrl => ({
  type: testTypes.SET_TRIBYTE_TEST_SECTION_URL,
  payload: { url, sectionId }
});
