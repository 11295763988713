import * as globalTypes from "./constants";
const initialState: IGlobalReducer = {
  fetchSpinner: false,
  fetchDetailsSpinner: false,
  actionSpinner: false
};

const handleActions = (state, action) => {
  switch (action.type) {
    case globalTypes.SET_ACTION_SPINNER:
      return {
        actionSpinner: action.payload
      };
    case globalTypes.SET_FETCH_SPINNER:
      return {
        fetchSpinner: action.payload
      };
    case globalTypes.SET_FETCH_DETAILS_SPINNER:
      return {
        fetchDetailsSpinner: action.payload
      };
    default:
      return {};
  }
};

const globalReducer = (state: IGlobalReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default globalReducer;
