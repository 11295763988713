import React, { CSSProperties } from "react";
import {
  faBells,
  faBook,
  faBookOpen,
  faCalculator,
  faClipboardListCheck,
  faEmptySet,
  faExclamationTriangle,
  faExternalLink,
  faFileImport,
  faHeadSideBrain,
  faLightbulbExclamation,
  faPenAlt,
  faPencil,
  faPennant,
  faPlusCircle,
  faSearch,
  faSignIn,
  faTh,
  faThList,
  faTrash,
  faUserGraduate,
  faUserCircle
} from "constants/icons/pro-light-svg-icons";
import {
  faArrowSquareLeft,
  faBallotCheck,
  faBan,
  faBook as faBookSolid,
  faBookAlt,
  faBookReader,
  faCalculatorAlt,
  faCalendarAlt,
  faCaretDown,
  faCaretUp,
  faChartLine,
  faCheck,
  faClipboardListCheck as faClipboardListCheckSolid,
  faClock as faClockSolid,
  faDizzy,
  faEdit,
  faExclamation,
  faFilePdf,
  faFileWord,
  faFlag,
  faInfoCircle,
  faKey,
  faKeyboard,
  faMarker,
  faMicroscope,
  faPenSquare,
  faPlay,
  faPlayCircle as faPlayCircleSolid,
  faSortDown,
  faTableList,
  faTrashAlt,
  faUser,
  faUsers,
  faPause,
  faExpand,
  faVolume,
  faVolumeSlash
} from "constants/icons/pro-solid-svg-icons";
import {
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faAt,
  faBars,
  faChalkboardTeacher,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClock,
  faCommentAltDots,
  faEllipsisH,
  faEllipsisV,
  faFilter,
  faFutbol,
  faGraduationCap,
  faGripLines,
  faHomeLgAlt,
  faInfoSquare,
  faLink,
  faMapMarkerAlt,
  faMinus,
  faPencilRuler,
  faPlayCircle,
  faPlus,
  faQuestionCircle,
  faShapes,
  faSignOut,
  faSortAmountDown,
  faSortAmountUp,
  faTimes
} from "constants/icons/pro-regular-svg-icons";

import { cuChallenge, cuDrill, cuPractice, cuReading } from "constants/icons/customIcons";
import colors from "constants/colors";

interface IIcon {
  className?: string;
  style?: CSSProperties;
  icon: IconType;
  onClick?: () => void;
  color?: string;
  size?: number;
}

const notSoAwesomeIcons = {
  "arrow-down": faArrowDown,
  "arrow-left": faArrowLeft,
  "arrow-square-left": faArrowSquareLeft,
  "arrow-up": faArrowUp,
  "ballot-check": faBallotCheck,
  "book-alt": faBookAlt,
  "book-open": faBookOpen,
  "book-solid": faBookSolid,
  "calendar-solid": faCalendarAlt,
  "caret-down": faCaretDown,
  "caret-up": faCaretUp,
  "chevron-down": faChevronDown,
  "chevron-left": faChevronLeft,
  "chevron-right": faChevronRight,
  "chevron-up": faChevronUp,
  "clipboard-list-check": faClipboardListCheck,
  "clipboard-list-check-solid": faClipboardListCheckSolid,
  "clock-solid": faClockSolid,
  "date-range": faCalendarAlt,
  "ellipsis-h": faEllipsisH,
  "empty-set": faEmptySet,
  "exclamation-triangle": faExclamationTriangle,
  "external-link": faExternalLink,
  "file-upload": faFileImport,
  "file-word": faFileWord,
  "grip-lines": faGripLines,
  "info-circle": faInfoCircle,
  "info-square": faInfoSquare,
  "lightbulb-exclamation": faLightbulbExclamation,
  "more-vert": faEllipsisV,
  "pen-marker": faMarker,
  "pen-square": faPenSquare,
  "pencil-ruler": faPencilRuler,
  "play-circle": faPlayCircle,
  "play-circle-filled": faPlayCircleSolid,
  "plus-circle": faPlusCircle,
  "question-circle": faQuestionCircle,
  "sign-in": faSignIn,
  "sort-down": faSortAmountDown,
  "sort-up": faSortAmountUp,
  "th-list": faThList,
  "writing and language": faEdit,
  "reading & writing": faBookReader,
  at: faAt,
  ban: faBan,
  bar: faBars,
  bells: faBells,
  book: faBook,
  calculator: faCalculator,
  check: faCheck,
  clock: faClock,
  close: faTimes,
  comment: faCommentAltDots,
  delete: faTrashAlt,
  dizzy: faDizzy,
  edit: faPencil,
  english: faEdit,
  exclamation: faExclamation,
  expand_more: faSortDown,
  file_pdf: faFilePdf,
  filter: faFilter,
  flag: faFlag,
  football: faFutbol,
  graduationCap: faGraduationCap,
  home: faHomeLgAlt,
  key: faKey,
  keyboard: faKeyboard,
  link: faLink,
  logout: faSignOut,
  "map-marker": faMapMarkerAlt,
  math: faCalculatorAlt,
  mind: faHeadSideBrain,
  minus: faMinus,
  pen: faPenAlt,
  pennant: faPennant,
  person: faUserCircle,
  play: faPlay,
  plus: faPlus,
  reading: faBookReader,
  science: faMicroscope,
  search: faSearch,
  shapes: faShapes,
  students: faUserGraduate,
  teacher: faChalkboardTeacher,
  th: faTh,
  trash: faTrash,
  user: faUser,
  users: faUsers,
  warning: faExclamationTriangle,
  writing: faEdit,
  summary: faChartLine,
  "table-list": faTableList,
  pause: faPause,
  expand: faExpand,
  "volume-on": faVolume,
  "volume-off": faVolumeSlash
};

const customIcons = {
  "cu-challenge": cuChallenge,
  "cu-drill": cuDrill,
  "cu-practice": cuPractice,
  "cu-reading": cuReading,
  "cu-workbook lesson": cuChallenge,
  "cu-assigned reading": cuReading
};

const Icon = ({ className, style, icon, onClick, color, size }: IIcon) => {
  const isCustom = (icon as string).includes("cu-");
  const iconConf = isCustom ? customIcons[icon] : notSoAwesomeIcons[icon as string]?.icon;
  const dimensions = {
    width: isCustom ? 256 : iconConf[0],
    height: isCustom ? 256 : iconConf[1]
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${dimensions.width} ${dimensions.height}`}
      style={{ width: size || 18, height: size || 18, ...style }}
      className={className}
      fill={color || style?.color || colors.white}
      onClick={() => (typeof onClick === "function" ? onClick() : {})}
    >
      {isCustom ? (
        iconConf.map((path: string, index: number) => <path d={path} key={`path_${index}`} />)
      ) : (
        <path d={iconConf[4]} />
      )}
    </svg>
  );
};

export default Icon;
