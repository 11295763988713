import React from "react";
import ReactLoading from "react-loading";
import colors from "constants/colors";

const ButtonSpinner = () => {
  return (
    <div className="button-spinner">
      <ReactLoading type="spin" color={colors.lightGrey} height={20} width={20} />
    </div>
  );
};

export default ButtonSpinner;
