import { getToken } from "Store/user/AuthService";

export const getActiveCourseId = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("activeCourseId");
  }
};

export const setActiveCourseId = (id) => {
  localStorage.setItem("activeCourseId", id);
};

export const defaultHeaders = () => ({
  Accept: "application/json",
  "x-Course-Id": getActiveCourseId(),
  "Access-Control-Allow-Origin": "*",
  //dsat
  //"X-Dev-Location-Id": '58fd2b7c-6ae0-4e9b-825e-db832ff16744',
  //digital-tests
  //"X-Dev-Location-Id": 'b5e7a7ae-1011-443b-80e4-bedc6d201047',
  "Content-Type": "application/json",
  Authorization: `Bearer ${getToken()}`
});

export const formDataHeaders = () => ({
  "Access-Control-Allow-Origin": "*",
  Authorization: `Bearer ${getToken()}`,
  "x-Course-Id": getActiveCourseId()
});
