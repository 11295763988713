export const SET_FETCH_SPINNER = "SET_FETCH_SPINNER";
export interface ISetFetchSpinner {
  type: typeof SET_FETCH_SPINNER;
  payload: boolean;
}

export const SET_FETCH_DETAILS_SPINNER = "SET_FETCH_DETAILS_SPINNER";
export interface ISetFetchDetailsSpinner {
  type: typeof SET_FETCH_DETAILS_SPINNER;
  payload: boolean;
}

export const SET_ACTION_SPINNER = "SET_ACTION_SPINNER";

export interface ISetActionSpinner {
  type: typeof SET_ACTION_SPINNER;
  payload: boolean;
}
