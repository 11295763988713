export const SET_SUMMARY = "SET_SUMMARY";

/* GET TO DO LIST */
export const HANDLE_FETCH_TODO_LIST = "HANDLE_FETCH_TODO_LIST";
export interface IGetTodoList {
  type: typeof HANDLE_FETCH_TODO_LIST;
}

export const SET_TODO_LIST = "SET_TODO_LIST";
export interface IGetTodoListResolved {
  type: typeof SET_TODO_LIST;
  payload: any;
}

/* FETCH SUMMARY  */
export const HANDLE_FETCH_SUMMARY = "HANDLE_FETCH_SUMMARY";
export interface IHandleFetchSummary {
  type: typeof HANDLE_FETCH_SUMMARY;
}

export interface IFetchSummaryResolved {
  type: typeof SET_SUMMARY;
  payload: StudentSummary[];
}
