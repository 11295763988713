import ApiClient from "Store/client";

export const loginApi = (body) => ApiClient(`api/login`, { method: "POST", body });

export const fetchUsersByTokenApi = () => ApiClient(`api/students/self`);

export const LogoutApi = () => ApiClient(`api/logout`, { method: "POST" });

export const changePasswordApi = (body) =>
  ApiClient(`api/reset-password/change`, { method: "POST", body });

export const resetPasswordApi = (body) => ApiClient(`api/reset-password`, { method: "POST", body });

export const currentLocationApi = () => ApiClient(`api/locations/current`);

export const updateStudentShowWelcomeVideoApi = (body) =>
  ApiClient(`api/commands/update-student-show-welcome-video`, { method: "PATCH", body });

export const updateStudentAddressApi = (body) =>
  ApiClient(`api/commands/update-student-address`, { method: "PATCH", body });

export const updateStudentAvatarApi = (body: any) =>
  ApiClient(`api/commands/update-student-avatar`, { method: "POST", body, type: "form-data" });

export const updateStudentApi = (body) =>
  ApiClient(`api/commands/update-student`, { method: "PATCH", body });

/* data :
 * @params
 * user_id: string
 * last_version_seen: string
 * */
export const updateLastVersionSeenApi = (body) =>
  ApiClient(`api/commands/update-student-last-version-seen`, { method: "PATCH", body });
