import { toast } from "react-toastify";

interface IErrorOptions {
  autoClose?: number | false;
}

export const Toast = (function () {
  let Methods = { error: null, success: null, info: null, warning: null };
  const TOAST_OPTIONS = { autoClose: 5000, hideProgressBar: false };
  Methods.error = function (error, providedError?, options?: IErrorOptions) {
    console.log(`%c${error}`, "font-size: 1.1em; color: orange");
    toast.error(providedError ? providedError?.message || JSON.stringify(providedError) : "", {
      ...TOAST_OPTIONS,
      ...(options || {})
    });
  };

  Methods.success = function (success) {
    toast.success(`✔️ ${success}`, TOAST_OPTIONS);
  };

  Methods.info = function (info) {
    toast.info(info, TOAST_OPTIONS);
  };

  Methods.warning = function (warning) {
    toast.warn(`⚠ ${warning}`, TOAST_OPTIONS);
  };

  return Methods;
})();

export const generateToastErrors = (error: object, errorsTexts: object) => {
  typeof error === "string"
    ? Toast.error(errorsTexts, error)
    : Object.keys(error).map((key) =>
        Toast.error(errorsTexts[key] || error[key], null, { autoClose: false })
      );
};

export const generateToastFromObject = (error: object | string, baseText?: string) => {
  const typeOfError = typeof error;
  const keys = Object.keys(error);
  let message: string | object = undefined;
  if (typeOfError === "object") {
    for (const key of keys) {
      switch (key) {
        case "email":
          message = "Your email address or password is invalid.";
          break;
        default:
          message = Object.keys(error)
            .map((key) => error[key].join(", "))
            .toString();
      }
    }
  } else if (typeOfError === "string") {
    message = error;
  }
  Toast.error(baseText || "", message);
};
