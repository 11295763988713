import ApiClient from "Store/client";

export const fetchStudentRoadMapApi = (studentId, subjectId) =>
  ApiClient<StudentUnit[]>(`api/students/${studentId}/units?subject_id=${subjectId}&lessons=true`);

export const updateStudentLessonStatusApi = (body) =>
  ApiClient(`api/commands/update-student-lesson-status`, { method: "PATCH", body, type: "text" });

export const fetchStudentLessonByIdApi = (student_id: string, lesson_id: string) =>
  ApiClient<StudentLesson>(`api/students/${student_id}/student_lessons/${lesson_id}`);

export const startStudentLessonSectionApi = (body) =>
  ApiClient(`api/commands/start-student-lesson-section`, { method: "PATCH", body, type: "text" });

export const completeStudentLessonSectionApi = (body) =>
  ApiClient(`api/commands/complete-student-lesson-section`, {
    method: "PATCH",
    body,
    type: "text"
  });

export const fetchStudentSectionByIdApi = (
  student_id: string,
  lesson_id: string,
  section_id: string
) => ApiClient(`api/students/${student_id}/student_lessons/${lesson_id}/sections/${section_id}`);

export const addAnswerToLessonApi = (body) =>
  ApiClient(`api/commands/add-student-lesson-problem-answer`, {
    method: "POST",
    body,
    type: "text"
  });

export const addStudentLessonProblemFlagApi = (body: LessonFlagBody) =>
  ApiClient(`api/commands/flag-student-lesson-problem`, { method: "POST", body, type: "text" });

export const addVideoWatchedTimeApi = (body) =>
  ApiClient(`api/commands/watch-student-lesson-problem-video`, {
    method: "POST",
    body,
    type: "text"
  });
