import * as todoTypes from "./constants";

const initialState: IDashboardReducer = {
  summary: null,
  todoList: []
};

const handleActions = (state, action) => {
  switch (action.type) {
    case todoTypes.SET_SUMMARY:
      return {
        summary: action.payload
      };

    case todoTypes.SET_TODO_LIST:
      return {
        todoList: action.payload
      };
    default:
      return {};
  }
};

const dashboardReducer = (state: IDashboardReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default dashboardReducer;
