import { useEffect } from "react";

export const useBreakpointAction = (
  breakpoint: number,
  callback: (isLessThanBreakpoint) => void
) => {
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < breakpoint) {
        callback(true);
      } else {
        callback(false);
      }
    };
    window?.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
};
