export const HANDLE_FETCH_STUDENT_SUBJECTS = "HANDLE_FETCH_STUDENT_SUBJECTS";
export const FETCH_STUDENT_SUBJECTS_RESOLVED = "FETCH_STUDENT_SUBJECTS_RESOLVED";

export const HANDLE_FETCH_STUDENT_UNITS_BY_SUBJECT = "HANDLE_FETCH_STUDENT_UNITS_BY_SUBJECT";
export const SET_UNITS = "SET_UNITS";

export const HANDLE_START_STUDENT_LESSON = "HANDLE_START_STUDENT_LESSON";
export const SET_STUDENT_LESSON = "SET_STUDENT_LESSON";

export const HANDLE_COMPLETE_STUDENT_LESSON = "HANDLE_COMPLETE_STUDENT_LESSON";

export const HANDLE_COMPLETE_STUDENT_LESSON_SECTION = "HANDLE_COMPLETE_STUDENT_LESSON_SECTION";

export const HANDLE_FETCH_STUDENTS_ROADMAP = "HANDLE_FETCH_STUDENTS_ROADMAP";
export const FETCH_STUDENT_ROADMAP_RESOLVED = "FETCH_STUDENT_ROADMAP_RESOLVED";

export const HANDLE_ADD_ANSWER_TO_LESSON = "HANDLE_ADD_ANSWER_TO_LESSON";
export const ADD_ANSWER_TO_LESSON_REJECTED = "ADD_ANSWER_TO_LESSON_REJECTED";

export const SET_STUDENT_SECTION_BY_ID = "SET_STUDENT_SECTION_BY_ID";

export const HANDLE_OPEN_DETAIL_LESSON_REVIEW = "HANDLE_OPEN_DETAIL_LESSON_REVIEW";

export const HANDLE_ADD_STUDENT_LESSON_QUESTION_FLAG = "HANDLE_ADD_STUDENT_LESSON_QUESTION_FLAG";

export const HANDLE_SET_LESSON_SECTION_SCORES = "HANDLE_SET_LESSON_SECTION_SCORES";

export const SET_RETURN_PAGE = "SET_RETURN_PAGE";

export const SET_WORKBOOK_ERRORS = "SET_WORKBOOK_ERRORS";

export interface IFetchStudentSubjects {
  type: typeof HANDLE_FETCH_STUDENT_SUBJECTS;
}

export interface IFetchStudentSubjectsResolved {
  type: typeof FETCH_STUDENT_SUBJECTS_RESOLVED;
  payload: Subject[];
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleFetchStudentUnitsBySubject {
  type: typeof HANDLE_FETCH_STUDENT_UNITS_BY_SUBJECT;
  payload: string;
}

export interface ISetUnits {
  type: typeof SET_UNITS;
  payload: Subject[];
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleStartStudentLesson {
  type: typeof HANDLE_START_STUDENT_LESSON;
  payload: {
    lessonId: string;
    sectionId: string;
  };
}

export interface ISetStudentLesson {
  type: typeof SET_STUDENT_LESSON;
  payload: StudentLesson;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleCompleteStudentLesson {
  type: typeof HANDLE_COMPLETE_STUDENT_LESSON;
  payload: StudentLesson;
}

export interface IHandleCompleteStudentLessonSection {
  type: typeof HANDLE_COMPLETE_STUDENT_LESSON_SECTION;
  lesson: StudentLesson;
  section: Section;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleFetchStudentsRoadmap {
  type: typeof HANDLE_FETCH_STUDENTS_ROADMAP;
  payload: any;
}

export interface IFetchStudentsRoadmapResolved {
  type: typeof FETCH_STUDENT_ROADMAP_RESOLVED;
  payload: any;
}
// ------------------------------------------------------------------------------------------------------------ //
export interface IHandleAddAnswerToLesson {
  type: typeof HANDLE_ADD_ANSWER_TO_LESSON;
  payload: AddWorksheetAnswerRequest;
}

export interface IAddAnswerToLessonRejected {
  type: typeof ADD_ANSWER_TO_LESSON_REJECTED;
  payload: AnswerRejectedPayload;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface ISetStudentSectionById {
  type: typeof SET_STUDENT_SECTION_BY_ID;
  payload: any;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleOpenDetailLessonReview {
  type: typeof HANDLE_OPEN_DETAIL_LESSON_REVIEW;
  payload: string;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleAddStudentLessonQuestionFlag {
  type: typeof HANDLE_ADD_STUDENT_LESSON_QUESTION_FLAG;
  body: LessonFlagBody;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface IHandleSetLessonSectionScores {
  type: typeof HANDLE_SET_LESSON_SECTION_SCORES;
  payload: any;
}
// ------------------------------------------------------------------------------------------------------------ //

export interface ISetReturnPage {
  type: typeof SET_RETURN_PAGE;
  page: string;
}

export const HANDLE_ADD_VIDEO_WATCHED_TIME = "HANDLE_ADD_VIDEO_WATCHED_TIME";
export interface IHandleAddVideoWatchedTime {
  type: typeof HANDLE_ADD_VIDEO_WATCHED_TIME;
  payload: LessonVideoWatchedTimeUpdate;
}

export const HANDLE_FETCH_LESSON = "HANDLE_FETCH_LESSON";
export interface IHandleFetchLesson {
  type: typeof HANDLE_FETCH_LESSON;
  payload: {
    lessonId: string;
  };
}

export const HANDLE_FETCH_STUDENT_SECTION_SCORE = "HANDLE_FETCH_STUDENT_SECTION_SCORE";
export interface IHandleFetchStudentSectionScore {
  type: typeof HANDLE_FETCH_STUDENT_SECTION_SCORE;
}
