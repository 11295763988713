import { menuItems } from "constants/menuItems";
import React from "react";
import SideNavItem from "components/Shared/SideNav/SideNavItem/SideNavItem";

interface IProps {
  menu: Menu[];
  activeLink: string;
  brandingColor: string;
  onHandleMenuItemClick: () => void;
}

const SideMenu: React.FC<IProps> = ({ menu, activeLink, brandingColor, onHandleMenuItemClick }) => {
  return (
    <ul className="menu-items">
      {menu?.map((item) => (
        <SideNavItem
          item={item}
          activeLink={activeLink}
          brandingColor={brandingColor}
          key={item.value}
          onClick={onHandleMenuItemClick}
        />
      ))}
    </ul>
  );
};
export default SideMenu;
