export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
};

export const formatConstants = (text: string): string => {
  return text
    .split("_")
    .map((t) => capitalizeFirstLetter(t))
    .join(" ");
};

/**
 * @params:
 *  - main - main element class, will be always displayed;
 *  - conditional: {key : value}, where key is also class name and value determines if it should be displayed.
 * The exception is className class where we are displaying full value of className not "className" as a name of class
 * **/

export const genClasses = (main: string, conditional?: object): string => {
  const objLength = Object.keys(conditional).length;
  const classes = Object.keys(conditional)
    ?.map((key) => (conditional[key] ? (key === "className" ? conditional[key] : key) : null))
    .filter((c) => c);
  return `${main}${objLength ? ` ${classes.join(" ")}` : ""}`;
};

export const getLessonPages = (item) => {
  if (item?.type?.label) {
    switch (item?.type?.label) {
      case "Module":
      case "Workbook Lesson":
        return `p. ${item.challenge_page} - ${item.practice_page}`;
      case "Drill":
        return `p. ${item.drill_page}`;
      default:
        return `p. ${item.starting_page} - ${item.ending_page}`;
    }
  }
};

export const getValuesByScore = (target, scoring) => {
  const { correct_count, question_count, grade } = scoring;
  let activeIndex = 0;
  if (question_count === 0) {
    activeIndex = 4;
  } else if (grade) {
    switch (grade) {
      case "GREAT":
        activeIndex = 0;
        break;
      case "ABOVE_AVERAGE":
        activeIndex = 1;
        break;
      case "AVERAGE":
        activeIndex = 2;
        break;
      case "BELOW_AVERAGE":
        activeIndex = 3;
        break;
      case "POOR":
        activeIndex = 4;
        break;
    }
  } else {
    const score = (correct_count / question_count) * 100;
    if (score >= 90) {
      activeIndex = 0;
    } else if (score < 90 && score > 75) {
      activeIndex = 1;
    } else if (score > 60 && score < 76) {
      activeIndex = 2;
    } else if (score >= 40 && score <= 60) {
      activeIndex = 3;
    } else if (score < 40) {
      activeIndex = 4;
    }
  }
  const colorList = [
    { label: "Great", color: "#74b287" },
    { label: "Above Average", color: "#a9c466" },
    { label: "Average", color: "#d8c539" },
    { label: "Below Average", color: "#e89258" },
    { label: "Poor", color: "#f27c7c" }
  ];
  if (target === "color") {
    return colorList[activeIndex].color;
  }
  return colorList[activeIndex].label;
};
