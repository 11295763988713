import React, { FC } from "react";
import Spinner from "components/Shared/Spinner/Spinner";
import colors from "constants/colors";

interface IProps {
  fetchingText?: string;
  local?: boolean;
}

const GlobalSpinner: FC<IProps> = ({ fetchingText, local }) => {
  return (
    <div className={`global-spinner-container${local ? " local" : ""}`}>
      <Spinner
        showSpinner={true}
        className="global-spinner"
        color={colors[local ? "grey" : "white"]}
      />
      <div className="global-spinner-text">{fetchingText || `Fetching Data...`}</div>
    </div>
  );
};
export default GlobalSpinner;
