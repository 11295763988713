import React from "react";
import Head from "next/head";

const Meta = () => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="sentry-trace" content="{{ span.toSentryTrace() }}" />
    <meta name="baggage" content="{{ serializeBaggage(span.getBaggage()) }}" />
    <meta charSet="utf-8" />
    <title />
  </Head>
);

export default Meta;
