import { defaultHeaders, formDataHeaders } from "utils/ActiveCourseService";

const API_URL = process.env.API_URL;

interface IRequestOptions {
  method: "POST" | "PATCH" | "DELETE";
  body: any;
  type: "json" | "text" | "blob" | "form-data";
}

interface IResponse<T> {
  data: T | null;
  meta: any | null;
  error: string | object | null;
  status?: number;
}

class ApiResponse<T> {
  data: T | any;
  status: string;
  meta?: Meta;
  message: string;
  error: string;
  errors: object;
}

const ApiClient = async <T>(
  url: string,
  options?: Partial<IRequestOptions>
): Promise<IResponse<T>> => {
  const headers = options?.type === "form-data" ? formDataHeaders() : defaultHeaders();

  const init: RequestInit = {
    headers,
    method: options?.method || "GET",
    ...(options?.body
      ? { body: options?.type === "form-data" ? options?.body : JSON.stringify(options?.body) }
      : {})
  };
  const response = await fetch(`${API_URL}/${url}`, init)
    .then((res) => res)
    .catch((err) => err);

  let processedResponse: ApiResponse<T> = new ApiResponse<T>();

  if (!options || !options?.type || options?.type === "json") {
    processedResponse = await response.json();
  } else {
    switch (options?.type) {
      case "text":
        processedResponse["data"] = await response.text();
        break;
      case "blob":
        const blob = await response.blob();
        processedResponse["data"] = await new Blob([blob]);
        break;
    }
  }

  if (response.status >= 200 && response.status < 300) {
    return {
      data: processedResponse?.data || processedResponse || null,
      meta: processedResponse?.meta || null,
      error: null
    };
  } else {
    return {
      data: null,
      meta: null,
      error: processedResponse?.errors || `${response.status}: ${processedResponse?.message}`,
      status: response.status
    };
  }
};

export default ApiClient;
