import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getActiveUser } from "Store/user/saga";
import {
  addStudentAnswerToTestApi,
  addStudentTestQuestionFlagApi, completeBlueBookTestApi,
  fetchProblemsBySectionApi,
  fetchStudentTestScoreApi,
  fetchTestByTestIdApi,
  fetchTestsByStudentIdApi,
  fetchTestsByStudentIdScoredApi,
  fetchTribyteTestSectionApi,
  postStudentTestScoreApi,
  scoreTribyteTestApi,
  startTribyteTestApi,
  updateStudentTestQuestionFlagStatusApi,
  updateStudentTestSectionStatusApi,
  updateStudentTestStatusApi
} from "./api";
import * as testTypes from "./constants";
import * as testSelectors from "./selectors";
import { setError } from "Store/user/actions";
import { makeSelectActiveUser, makeSelectActiveCourse } from "Store/user/selectors";
import {
  setTestProblems,
  fetchStudentTestScoresResolved,
  fetchTestByTestIdResolved,
  handleGetProblemsByStudentTestSectionId,
  handleGetStudentTestScores,
  handleGetTestByTestId,
  resetTestError,
  sendTestError,
  getScoredTestsResolved,
  setStudentActiveTest,
  setStudentActiveTestSection,
  getProblemsByStudentTestSectionIdRejected,
  getScoredTestsPending,
  getScoredTestsRejected,
  addStudentAnswerToTestRejected,
  setPagination,
  setStudentTests,
  setFetchProblemsSpinner,
  handleFetchStudentTests,
  setTribyteTestSectionUrl, handleStartTribyteTest
} from "./actions";
import Router from "next/router";
import {
  makeSelectStudentActiveTest,
  makeSelectStudentActiveTestProblems,
  makeSelectStudentActiveTestSection
} from "./selectors";

import { Toast } from "utils/toast";
import { setActionSpinner, setFetchDetailsSpinner, setFetchSpinner } from "Store/global/actions";
import ERROR from "constants/localization/error";
import {wait} from "next/dist/build/output/log";
import {HANDLE_START_TRIBYTE_TEST} from "./constants";

export function* renderApiParameters(selector, type: string) {
  const pagination = yield select(
    selector[`makeSelect${type[0].toUpperCase() + type.slice(1)}Pagination`]
  );
  // Create string from pagination
  const query = {
    page: pagination.page ? "page=" + pagination.page : null,
    search: pagination.search ? "search=" + pagination.search : null,
    per_page: pagination.per_page ? "per_page=" + pagination.per_page : null,
    sort_by: pagination.sort_by ? "sort_by=" + pagination.sort_by : null,
    sort: pagination.sort ? "sort_type=" + pagination.sort : null,
    stats: pagination.stats ? "stats=" + pagination.stats : null,
    subject_id: pagination.subjects ? "subject_id=" + pagination.subjects : null,
    unit_id: pagination.units ? "unit_id=" + pagination.units : null,
    lesson_type: pagination.lesson_types ? "lesson_type=" + pagination.lesson_types : null,
    template_type: pagination.template_type ? "type=" + pagination.template_type : null,
    // Worksheet parameters:
    category_id: pagination.categories ? "category_id=" + pagination.categories : null,
    worksheet_type: pagination.worksheet_types
      ? "worksheet_type=" + pagination.worksheet_types
      : null,
    worksheet_content_type: pagination.content_types
      ? "worksheet_content_type=" + pagination.content_types
      : null,
    status:
      pagination.statuses && pagination.statuses.length > 0
        ? "status=" + pagination.statuses?.map((s) => s.value).join(",")
        : null,
    due_status: pagination.due_statuses ? "due=" + pagination.due_statuses.toLowerCase() : null
  };
  const s = Object.keys(query)
    ?.map((key) => (query[key] ? `${query[key]}` : null))
    .filter((k) => k !== null)
    .join("&");
  return "&" + s;
}

export const convertMetaToPagination = (meta) => {
  return {
    page: meta.current_page,
    per_page: meta.per_page,
    total: meta.total,
    last_page: meta.last_page,
    from: meta.from,
    to: meta.to
  };
};

function* handleFetchTestsByStudentIdSaga() {
  yield put(setFetchSpinner(true));
  try {
    const activeUser: User = yield getActiveUser();

    const { data, error } = yield fetchTestsByStudentIdApi(activeUser.id);
    if (error) yield put(setError("Something went wrong while fetching tests."));

    yield put(setStudentTests(data));
    yield put(setFetchSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    yield put(setFetchSpinner(false));
  }
}

// ///Start Student Test Saga////

function* handleStartStudentTestSaga(action: testTypes.IHandleStartStudentTest) {
  yield put(setFetchDetailsSpinner(true));
  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    const studentTest = action.payload;

    // @TODO: refactor needed
    if (studentTest.status === "ASSIGNED") {
      yield updateStudentTestStatusApi({
        student_test_id: studentTest.id,
        status: "STARTED"
      });
    }
    const { data: updatedStudentTest } = yield fetchTestByTestIdApi(
      activeUser.id,
      action.payload.id
    );
    yield put(setStudentActiveTest(updatedStudentTest));
    yield Router.push("/tests/[testId]", `/tests/${studentTest.id}`, {
      shallow: true
    });
    yield put(setFetchDetailsSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleStartStudentTest saga", error);
    yield put(setFetchDetailsSpinner(false));
  }
}

function* handleCompleteStudentTestSaga(action: testTypes.IHandleCompleteStudentTest) {
  yield put(setActionSpinner(true));
  try {
    const studentActiveTest: Test = yield action.payload;

    const body = {
      student_test_id: studentActiveTest.student_test_id,
      status: "COMPLETED"
    };

    const activeCourse: Course = yield select(makeSelectActiveCourse);

    const { error } = yield updateStudentTestStatusApi(body);

    if (error) {
      Toast.error("Something went wrong updating test status to COMPLETE.");
      return yield put(setActionSpinner(false));
    }

    yield delay(4000);

    yield put(setStudentActiveTest({ ...studentActiveTest, status: "COMPLETED" }));
    if (activeCourse && !activeCourse.active) {
      return yield Router.push(`/tests`, undefined, {
        shallow: false
      });
    }
    return yield Router.push(`/tests/${studentActiveTest.student_test_id}`, undefined, {
      shallow: false
    });
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  } finally {
    yield put(setActionSpinner(false));
  }
}

function* handleUpdateStudentTestStatusSaga(action) {
  try {
    yield updateStudentTestStatusApi({
      student_test_id: action.studentTestId,
      status: action.status
    });
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleUpdateStudentTestStatus saga", error);
  }
}
// //Start Student Test Section Saga////
function* handleStartStudentTestSectionSaga(action: testTypes.IHandleStartStudentTestSection) {
  yield put(setActionSpinner(true));
  try {
    const studentTest: Test = yield action.studentTest;
    const studentTestSection: TestSection = yield action.studentTestSection;

    if (
      studentTestSection.test_section_status === "CREATED" ||
      studentTestSection.test_section_status === "ASSIGNED"
    ) {
      yield call(handleUpdateStudentTestSectionStatusSaga, {
        payload: {
          studentTestId: studentTestSection.student_test_id,
          studentTestSectionId: studentTestSection.id,
          status: "STARTED"
        }
      });

      const updatedStudentTest: Test = yield {
        ...studentTest,
        sections: studentTest?.sections?.map((section) => {
          if (section.test_section_id === studentTestSection.test_section_id) {
            return {
              ...section,
              test_section_status: "STARTED"
            };
          }
          return section;
        })
      };

      yield put(setStudentActiveTest(updatedStudentTest));
    }

    yield put(setStudentActiveTestSection(studentTestSection));

    yield put(
      handleGetProblemsByStudentTestSectionId(
        studentTestSection.student_test_id,
        studentTestSection.id
      )
    );
    yield put(setActionSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleStartStudentTestSection saga", error);
    yield put(setActionSpinner(false));
  }
}

function* handleCompleteStudentTestSectionSaga() {
  yield put(setActionSpinner(true));
  try {
    const studentTest: Test = yield select(makeSelectStudentActiveTest);
    const studentTestSection: TestSection = yield select(makeSelectStudentActiveTestSection);
    const student = yield select(makeSelectActiveUser);
    yield call(handleUpdateStudentTestSectionStatusSaga, {
      payload: {
        studentTestId: studentTest.student_test_id,
        studentTestSectionId: studentTestSection.id,
        status: "COMPLETED"
      }
    });

    const sections: TestSection[] = studentTest?.sections?.map((section) => {
      if (section.test_section_id === studentTestSection.test_section_id) {
        return {
          ...section,
          test_section_status: "COMPLETED"
        };
      }
      return section;
    });

    const { data: activeStudentTest } = yield fetchTestByTestIdApi(student?.id, studentTest.id);

    if (studentTest.sections.length !== activeStudentTest.sections.length) {
      yield put(setStudentActiveTest(activeStudentTest));
      yield put(setActionSpinner(false));
      return yield Router.push(`/tests/${activeStudentTest.student_test_id}`, undefined, {
        shallow: false
      });
    } else {
      const isAllSectionsCompleted = sections.every(
        (section) => section.test_section_status === "COMPLETED"
      );
      if (isAllSectionsCompleted) {
        yield call(handleCompleteStudentTestSaga, {
          payload: studentTest,
          type: "HANDLE_COMPLETE_STUDENT_TEST"
        });
        return yield put(setActionSpinner(false));
      } else {
        yield put(
            setStudentActiveTest({
              ...studentTest,
              sections
            })
        );
        yield put(setActionSpinner(false));
        return yield Router.push(`/tests/${activeStudentTest.student_test_id}`, undefined, {
          shallow: false
        });
      }
    }
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleCompleteStudentTestSection saga", error);
    yield put(setActionSpinner(false));
  }
}

function* handleUpdateStudentTestSectionStatusSaga(action) {
  const { studentTestId, studentTestSectionId, status } = action.payload;
  if (status === "COMPLETED") {
    yield put(setActionSpinner(true));
  }
  try {
    yield updateStudentTestSectionStatusApi({
      student_test_id: studentTestId,
      student_test_section_id: studentTestSectionId,
      student_test_section_status: status
    });

    yield put(setActionSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleUpdateStudentTestSectionStatus saga", error);
    yield put(setActionSpinner(false));
  }
}

function* handleFetchProblemByStudentTestSectionIdSaga(
  action: testTypes.IHandleFetchProblemsByStudentTestSectionId
) {
  yield put(setFetchProblemsSpinner(true));
  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    const { data: testSectionProblems } = yield fetchProblemsBySectionApi(
      activeUser.id,
      action.studentTestId,
      action.testSectionId
    );

    yield put(setTestProblems(testSectionProblems));
  } catch (error) {
    yield put(
      getProblemsByStudentTestSectionIdRejected(
        error.message ? error.message : "Something went wrong."
      )
    );
    console.warn("Error occurred in the handleFetchProblemByStudentTestSectionId saga", error);
  } finally {
    yield put(setFetchProblemsSpinner(false));
  }
}

function* handleAddStudentAnswerSaga(action: testTypes.IHandleAddStudentAnswerToTest) {
  try {
    const studentTest: Test = yield select(makeSelectStudentActiveTest);

    const requestBody = yield {
      ...action.payload,
      student_test_id: studentTest.student_test_id
    };
    const { error } = yield addStudentAnswerToTestApi(requestBody);
    if (error) {
      yield put(
        addStudentAnswerToTestRejected({
          problem_id: action.payload.test_problem_id
        })
      );
    }
  } catch (error) {
    yield put(
      addStudentAnswerToTestRejected({
        problem_id: action.payload.test_problem_id
      })
    );
    console.warn("Error occurred in the handleAddStudentAnswer saga", error);
  }
}

function* handleAddStudentTestFlagSaga(action: testTypes.IHandleAddStudentTestQuestionFlag) {
  try {
    const {
      data: { id: flagId }
    } = yield addStudentTestQuestionFlagApi(action.payload);
    const problemSection = yield select(makeSelectStudentActiveTestProblems);
    const updatedProblems = yield {
      ...problemSection,
      problems: problemSection?.problems?.map((problem) => {
        if (problem.id === action.payload.test_problem_id) {
          return {
            ...problem,
            flag: {
              id: flagId,
              status: "FLAGGED"
            }
          };
        }
        return problem;
      })
    };
    yield put(setTestProblems(updatedProblems));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  }
}

function* handleUpdateStudentTestFlagSaga(action: testTypes.IHandleUpdateStudentTestQuestionFlag) {
  try {
    yield updateStudentTestQuestionFlagStatusApi(action.postBody);
    const problemSection: ProblemSection = yield select(makeSelectStudentActiveTestProblems);

    const updatedProblems = yield {
      ...problemSection,
      problems: problemSection?.problems?.map((problem) => {
        if (problem.id === action.postBody.test_problem_id) {
          return {
            ...problem,
            flag: {
              id: action.postBody.flag_id,
              status: "UN_FLAGGED"
            }
          };
        }
        return problem;
      })
    };

    yield put(setTestProblems(updatedProblems));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  }
}

// detail Test review

function* handleOpenDetailTestReviewSaga(action: testTypes.IHandleOpenDetailTestReview) {
  yield put(setActionSpinner(true));

  try {
    const studentTestId: string = action.payload;
    yield put(handleGetTestByTestId(studentTestId));
    yield put(handleGetStudentTestScores(studentTestId));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  } finally {
    yield put(setActionSpinner(false));
  }
}
function* handleFetchTestByTestIdSaga(action: testTypes.IHandleFetchTestByTestId) {
  yield put(setActionSpinner(true));
  try {
    const student = yield call(getActiveUser);
    if (!student || !action.payload) return;
    const { data: studentTest } = yield fetchTestByTestIdApi(student?.id, action.payload);
    yield put(fetchTestByTestIdResolved(studentTest));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleFetchTestByTestId saga", error);
  } finally {
    yield put(setActionSpinner(false));
  }
}

function* handleFetchSectionBySectionIdSaga(action: testTypes.IHandleFetchSectionBySectionId) {
  try {
    const student = yield select(makeSelectActiveUser);
    const {
      data: { sections }
    } = yield fetchTestByTestIdApi(student.id, action.studentTestId);
    const sectionId: string = action.sectionId;

    const studentTestSection: TestSection = sections
      .filter((section) => section.id === sectionId)
      .pop();
    yield put(setTestProblems(studentTestSection));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleFetchSectionBySectionId saga", error);
  }
}

function* handleFetchStudentTestAnswersSaga(
  action: testTypes.IHandleFetchStudentAnswersByStudentTestId
) {
  yield put(setFetchProblemsSpinner(true));
  try {
    const student = yield select(makeSelectActiveUser);

    const { data: studentTestProblem } = yield fetchProblemsBySectionApi(
      student.id,
      action.studentTestId,
      action.sectionId
    );
    yield put(setTestProblems(studentTestProblem));
  } catch (error) {
    console.warn("Error occurred in the handleFetchStudentTestAnswers saga", error);
  } finally {
    yield put(setFetchProblemsSpinner(false));
  }
}

function* handleGetScoredTestsSaga() {
  yield put(getScoredTestsPending());
  try {
    const student: User = yield select(makeSelectActiveUser);
    const parameters = yield renderApiParameters(testSelectors, "scoredTests");

    const { data: scoredTests, meta } = yield fetchTestsByStudentIdScoredApi(
      student?.id,
      parameters + "&sort_by=name&sort_type=desc"
    );
    yield put(setPagination(convertMetaToPagination(meta), "scoredTests"));
    yield put(getScoredTestsResolved(scoredTests));
  } catch (error) {
    yield put(getScoredTestsRejected(error.message ? error.message : "Something went wrong."));
  }
}

function* handleFetchStudentTestScoresSaga(action: testTypes.IHandleFetchStudentTestScores) {
  try {
    const student = yield select(makeSelectActiveUser);
    const studentTestScores = yield handleFetchStudentTestScoreApi(
      student.id,
      action.studentTestId
    );
    if (!studentTestScores) {
      return null;
    }

    yield put(fetchStudentTestScoresResolved(studentTestScores));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleGetStudentTestScores saga", error);
  }
}

function* handleFetchStudentTestScoreApi(studentId, studentTestId) {
  try {
    yield postStudentTestScoreApi(studentId, studentTestId);
    const { error, data } = yield fetchStudentTestScoreApi(studentId, studentTestId);
    if (error) {
      throw new Error(error);
    }
    return data;
  } catch (error) {
    yield put(
      sendTestError({
        propertyName: "testScores",
        message: `Something went wrong fetching test scores. ${error.message}`
      })
    );
    yield delay(500);
    yield put(resetTestError(["testScores"]));
    console.warn("Error occurred in the handleFetchStudentTestScoreApi saga", error);
  }
}

function* handleStartTribyteTestSaga(action: testTypes.IHandleStartTribyteTest) {
  try {
    const { test } = action.payload;
    const {
      data: { url }
    } = yield startTribyteTestApi({ student_test_id: test.id });
    yield call(updateStudentTestStatusApi, {
      student_test_id: test.id,
      status: "STARTED"
    });
    const updatedTest = { ...test, url };
    yield put(setStudentActiveTest(updatedTest));
  } catch (error) {}
}

function* handleStartBlueBookTestSaga(action: testTypes.IHandleStartBlueBookTest) {
  try {
    const { test } = action.payload;
    const activeUser: User = yield select(makeSelectActiveUser);
    const { data: updatedStudentTest } = yield fetchTestByTestIdApi(
        activeUser.id,
        test.id
    );
    yield put(setStudentActiveTest(updatedStudentTest));
  } catch (error) {}
}

function* handleScoreTribyteTestSaga() {
  yield put(setFetchSpinner(true));
  try {
    const activeTest = yield select(makeSelectStudentActiveTest);
    const {
      data,
      meta,
      error,
      status
    } = yield scoreTribyteTestApi({
      student_test_id: activeTest.id
    });
    if (status == 404) {
      yield call(handleStartTribyteTestSaga,{type: HANDLE_START_TRIBYTE_TEST, payload: {test: activeTest}});
      return;
    }
    if (error) {
      return Toast.error(error);
    }
    yield delay(5000)
    yield put(setStudentActiveTest(null));
    yield call(handleFetchTestsByStudentIdSaga);
  } catch (error) {
    return Toast.error(error);
  } finally {
    yield put(setFetchSpinner(false));
  }
}

function* handleScoreBlueBookTestSaga(action: testTypes.IHandleScoreBlueBookTest) {
  const { test, subjects, answers } = action.payload;

  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    const {error, data} = yield call(completeBlueBookTestApi, { student_test_id: test.id, subjects, answers });
    if (error) {
      throw new Error('Wrong Data');
    }
    yield delay(5000)
    const { data: updatedStudentTest } = yield fetchTestByTestIdApi(
        activeUser.id,
        test.id
    );
    yield put(setStudentActiveTest(updatedStudentTest));
  } catch (error) {
    return Toast.error(error, error);
  }
}

function* handleFetchTribyteTestSectionSaga(action: testTypes.IHandleFEtchTribyteTestSection) {
  try {
    yield put(setActionSpinner(true));
    const { test } = action.payload;
    const testSections = test.sections;

    for (const section of testSections) {
      const {
        data: { url },
        error
      } = yield fetchTribyteTestSectionApi({
        student_test_id: section.student_test_id,
        student_test_section_id: section.id
      });
      if (error) {
        return Toast.error(ERROR.handleFetchTribyteTestSectionSaga, error);
      }
      yield put(setTribyteTestSectionUrl(url, section.id));
    }
  } catch (error) {
    Toast.error(ERROR.handleFetchTribyteTestSectionSaga, error);
  } finally {
    yield put(setActionSpinner(false));
  }
}

export default function* testSaga() {
  yield takeLatest(testTypes.HANDLE_FETCH_TESTS_BY_STUDENT_ID, handleFetchTestsByStudentIdSaga);
  yield takeLatest(testTypes.HANDLE_FETCH_TEST_BY_TEST_ID, handleFetchTestByTestIdSaga);
  yield takeLatest(testTypes.HANDLE_UPDATE_STUDENT_TEST_STATUS, handleUpdateStudentTestStatusSaga);
  yield takeLatest(
    testTypes.HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID,
    handleFetchStudentTestAnswersSaga
  );
  yield takeLatest(
    testTypes.HANDLE_COMPLETE_STUDENT_TEST_SECTION,
    handleCompleteStudentTestSectionSaga
  );
  yield takeLatest(testTypes.HANDLE_COMPLETE_STUDENT_TEST, handleCompleteStudentTestSaga);
  yield takeLatest(testTypes.HANDLE_GET_SCORED_TESTS, handleGetScoredTestsSaga);
  yield takeEvery(testTypes.HANDLE_ADD_STUDENT_ANSWER_TO_TEST, handleAddStudentAnswerSaga);
  yield takeLatest(testTypes.HANDLE_FETCH_STUDENT_TEST_SCORES, handleFetchStudentTestScoresSaga);
  yield takeEvery(
    testTypes.HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID,
    handleFetchProblemByStudentTestSectionIdSaga
  );
  yield takeLatest(testTypes.HANDLE_START_STUDENT_TEST, handleStartStudentTestSaga);
  yield takeLatest(testTypes.HANDLE_START_STUDENT_TEST_SECTION, handleStartStudentTestSectionSaga);
  yield takeLatest(testTypes.HANDLE_FETCH_SECTION_BY_SECTION_ID, handleFetchSectionBySectionIdSaga);
  yield takeLatest(testTypes.HANDLE_OPEN_DETAIL_TEST_REVIEW, handleOpenDetailTestReviewSaga);
  yield takeLatest(testTypes.HANDLE_ADD_STUDENT_TEST_QUESTION_FLAG, handleAddStudentTestFlagSaga);
  yield takeLatest(testTypes.HANDLE_START_TRIBYTE_TEST, handleStartTribyteTestSaga);
  yield takeLatest(testTypes.HANDLE_START_BLUEBOOK_TEST, handleStartBlueBookTestSaga);
  yield takeLatest(
    testTypes.HANDLE_UPDATE_STUDENT_TEST_QUESTION_FLAG,
    handleUpdateStudentTestFlagSaga
  );
  yield takeLatest(testTypes.HANDLE_SCORE_TRIBYTE_TEST, handleScoreTribyteTestSaga);
  yield takeLatest(testTypes.HANDLE_SCORE_BLUEBOOK_TEST, handleScoreBlueBookTestSaga);
  yield takeLatest(testTypes.HANDLE_FETCH_TRIBYTE_TEST_SECTION, handleFetchTribyteTestSectionSaga);
}
