import { call, delay, put, select, takeLatest, takeEvery } from "redux-saga/effects";
import {
  addAnswerToWorksheetApi,
  addStudentWorksheetProblemFlagApi,
  addWorksheetVideoWatchedTimeApi,
  fetchWorksheetByIdApi,
  fetchWorksheetsApi,
  updateStudentWorksheetStatusApi
} from "./api";
import moment from "moment";
import * as worksheetActions from "./actions";
import { setError } from "Store/user/actions";
import * as worksheetTypes from "./constants";
import { makeSelectCurrentWorksheet } from "./selectors";
import { getActiveUser } from "Store/user/saga";
import { Toast } from "utils/toast";
import { setActionSpinner, setFetchDetailsSpinner, setFetchSpinner } from "Store/global/actions";
import { makeSelectActiveUser } from "Store/user/selectors";

function* handleFetchWorksheets() {
  yield put(setFetchSpinner(true));
  try {
    const activeUser: User = yield getActiveUser();

    const { data } = yield fetchWorksheetsApi(activeUser.id);

    const formattedWorkSheet = data.reduce(
      (final, current) => {
        if (current.status.includes("COMPLETED")) {
          final.completed.push(current);
        } else if (!current.due_date) {
          final.noDueDate.push(current);
        } else if (current.due_status === "OVERDUE") {
          final.overdue.push(current);
        } else if (moment(current.due_date).isSame(new Date(), "day")) {
          final.dueToday.push(current);
        } else if (moment(current.due_date).isSame(new Date(), "week")) {
          final.dueThisWeek.push(current);
        } else {
          final.dueAfterThisWeek.push(current);
        }
        return final;
      },
      {
        assigned: [],
        completed: [],
        overdue: [],
        dueToday: [],
        dueThisWeek: [],
        dueAfterThisWeek: [],
        noDueDate: []
      }
    );
    yield put(worksheetActions.setWorksheets(formattedWorkSheet));
    yield put(setFetchSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error in FetchWorksheets saga", error);
    yield put(setFetchSpinner(false));
  }
}

function* handleStartStudentWorksheet(action: worksheetTypes.IHandleStartStudentWorksheet) {
  yield put(setFetchDetailsSpinner(true));
  const { worksheet } = action;
  try {
    if (!worksheet) return null;

    // Set the worksheet as the active worksheet
    yield put(worksheetActions.setActiveWorksheet(worksheet));

    // Update the worksheet status to started if not already started
    if (worksheet.status === "ASSIGNED") {
      yield call(updateWorksheetStatus, worksheet.id, "STARTED");
    }

    // Update the active worksheet if needed
    let updatedWorksheet: Worksheet = yield call(fetchStudentWorksheetById, worksheet.id);
    yield put(worksheetActions.setActiveWorksheet(updatedWorksheet));

    yield put(setFetchDetailsSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleStartStudentWorksheet saga", error);
    yield put(setFetchDetailsSpinner(false));
  }
}

function* handleOpenWorksheetReview(action) {
  yield put(setFetchDetailsSpinner(true));
  try {
    const worksheet: Worksheet = yield call(fetchStudentWorksheetById, action.payload);
    yield put(worksheetActions.setActiveWorksheet(worksheet));

    // If the worksheet has a complete status, move user to the scoring page.
    yield put(setFetchDetailsSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleOpenWorksheetReview saga", error);
    yield put(setFetchDetailsSpinner(false));
  }
}

function* updateWorksheetStatus(worksheetId, status) {
  if (status === "COMPLETED") {
    yield put(setActionSpinner(true));
  }
  try {
    const body = yield {
      student_worksheet_id: worksheetId,
      status
    };
    yield updateStudentWorksheetStatusApi(body);

    yield put(setActionSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    yield put(setActionSpinner(false));
    console.warn("Error occurred in the updateWorksheetStatus", error);
  }
}

function* fetchStudentWorksheetById(worksheetId) {
  yield put(setFetchDetailsSpinner(true));
  try {
    const activeUser: User = yield getActiveUser();

    const { data, error } = yield fetchWorksheetByIdApi(activeUser?.id, worksheetId);

    if (error) {
      yield put(setFetchDetailsSpinner(false));
      return Toast.error("Something went wrong while fetching worksheet.", error);
    }

    yield put(worksheetActions.setActiveWorksheet(data));
    yield put(setFetchDetailsSpinner(false));
    return data;
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    yield put(setFetchDetailsSpinner(false));
    console.warn("Error occurred in the fetchStudentWorksheetById", error);
  }
}

function* handleCompleteStudentWorksheet(action: worksheetTypes.IHandleCompleteStudentWorksheet) {
  const { worksheet } = action;
  yield put(setActionSpinner(true));
  try {
    if (worksheet.status !== "COMPLETED") {
      yield call(updateWorksheetStatus, worksheet.id, "COMPLETED");
    }

    yield delay(1000);
    yield put(worksheetActions.setFromCompletedWorksheet(true));
    yield put(worksheetActions.handleFetchWorksheet(worksheet.id));
    yield put(setActionSpinner(false));
  } catch (error) {
    yield put(setActionSpinner(false));
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleCompleteStudentWorksheet saga", error);
  }
}

function* handleAddAnswerToWorksheet(action: worksheetTypes.IHandleAddAnswerToWorksheet) {
  if (!action.body?.problem_id) return;
  try {
    const { error } = yield addAnswerToWorksheetApi(action.body);
    if (error) {
      yield put(
        worksheetActions.addAnswerToWorksheetRejected({
          problem_id: action.body.problem_id
        })
      );
    }
  } catch (error) {
    yield put(
      worksheetActions.addAnswerToWorksheetRejected({
        problem_id: action.body.problem_id
      })
    );
    console.warn("Error occurred in the handleAddAnswerToWorksheet saga", error);
  }
}

function* handleAddStudentWorksheetFlag(
  action: worksheetTypes.IHandleAddStudentWorksheetProblemFlag
) {
  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    const { data } = yield fetchWorksheetByIdApi(
      activeUser?.id,
      action.payload.student_worksheet_id
    );

    const updatedWorksheet: Worksheet = yield {
      ...data,
      problems: data.problems?.map((problem) => {
        if (problem.id === action.payload.problem_id) {
          return {
            ...problem,
            flag_status: action.payload.flag_status
          };
        }
        return problem;
      })
    };
    yield put(worksheetActions.setActiveWorksheet(updatedWorksheet));
    // api call
    const { error } = yield addStudentWorksheetProblemFlagApi(action.payload);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleAddStudentWorksheetFlag saga", error);
  }
}

function* handleAddWorksheetVideoWatchedTime(
  body: worksheetTypes.IHandleAddWorksheetVideoWatchedTime
) {
  try {
    yield addWorksheetVideoWatchedTimeApi(body.payload);
  } catch (error) {
    console.warn("Error occurred in the addWorksheetVideoWatchedTime func", error);
  }
}

export default function* worksheetSaga() {
  yield takeLatest(worksheetTypes.HANDLE_FETCH_WORKSHEETS, handleFetchWorksheets);
  yield takeEvery(worksheetTypes.HANDLE_ADD_ANSWER_TO_WORKSHEET, handleAddAnswerToWorksheet);
  yield takeLatest(worksheetTypes.HANDLE_START_STUDENT_WORKSHEET, handleStartStudentWorksheet);
  yield takeLatest(worksheetTypes.HANDLE_FETCH_WORKSHEET, handleOpenWorksheetReview);
  yield takeLatest(
    worksheetTypes.HANDLE_COMPLETE_STUDENT_WORKSHEET,
    handleCompleteStudentWorksheet
  );
  yield takeLatest(
    worksheetTypes.HANDLE_ADD_STUDENT_WORKSHEET_PROBLEM_FLAG,
    handleAddStudentWorksheetFlag
  );
  yield takeLatest(
    worksheetTypes.HANDLE_ADD_WORKSHEET_VIDEO_WATCHED_TIME,
    handleAddWorksheetVideoWatchedTime
  );
}
