import Head from "next/head";
import Router from "next/router";
import React, { useEffect, useState, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleLogout, setUserIsLogged } from "Store/user/actions";
import {
  makeSelectActiveUser,
  makeSelectLocation,
  makeSelectUserIsLogged
} from "Store/user/selectors";
import { loggedIn } from "Store/user/AuthService";
import Meta from "./Meta";
import config from "../package.json";
import { getSlidesToDisplay } from "utils/newsHelpers";
import NEWS from "constants/localization/news";
import Toast from "components/Shared/Toast/Toast";
import { LoggedInWrapper } from "layout/LoggedInWrapper/LoggedInWrapper";
import HeaderProvider from "utils/context/HeaderContext";
import ErrorModal from "./Shared/Modals/ErrorModal/ErrorModal";
import { LoggedOutWrapper } from "layout/LoggedOutWrapper/LoggedOutWrapper";
import GlobalSpinner from "./Shared/Spinner/GlobalSpinner/GlobalSpinner";

interface IProps {
  children: React.ReactElement;
}

export const Page: FC<IProps> = ({ children }) => {
  const [showNewsModal, setShowNewsModal] = useState(false);
  const activeUser = useSelector(makeSelectActiveUser);
  const isLogged = useSelector(makeSelectUserIsLogged);
  const currentLocation = useSelector(makeSelectLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Router.router.route !== "/reset") {
      const isUserLogged = loggedIn();
      if (!loggedIn() || (loggedIn() && activeUser && activeUser.type !== "STUDENT")) {
        dispatch(handleLogout());
      } else {
        dispatch(setUserIsLogged(isUserLogged));
      }
    }
  }, []);

  const instructorAvatar =
    typeof window !== "undefined" ? localStorage.getItem("impersonated") : null;

  const calculateVersionsDifference = () => {
    if (!activeUser?.last_version_seen) return true;
    const lastVersionSaw = activeUser?.last_version_seen.split(".")?.map((n) => parseInt(n, 10));
    const appVersion = config.version.split(".")?.map((n) => parseInt(n, 10));

    if (appVersion[0] > lastVersionSaw[0]) {
      return true;
    } else if (appVersion[0] === lastVersionSaw[0]) {
      if (appVersion[1] > lastVersionSaw[1]) {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (activeUser && !activeUser?.last_version_seen && NEWS.length > 0) {
      setShowNewsModal(true);
    }
    if (
      calculateVersionsDifference() &&
      activeUser &&
      currentLocation &&
      !instructorAvatar &&
      getSlidesToDisplay(activeUser?.last_version_seen).length > 0
    ) {
      setShowNewsModal(true);
    }
  }, [activeUser, currentLocation]);

  return currentLocation ? (
    <>
      <Meta />
      <Head>
        <title>{currentLocation?.name || " "}</title>
      </Head>
      <>
        {isLogged ? (
          <HeaderProvider>
            <LoggedInWrapper>{children}</LoggedInWrapper>
          </HeaderProvider>
        ) : (
          <LoggedOutWrapper location={currentLocation}>{children}</LoggedOutWrapper>
        )}
      </>
      <ErrorModal />
      <Toast />
    </>
  ) : (
    <GlobalSpinner />
  );
};

export default Page;
