import Router from "next/router";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorksheets } from "Store/worksheet/actions";
import { handleFetchStudentTests } from "Store/tests/actions";
import { setActiveCourse } from "Store/user/actions";
import {
  makeSelectActiveCourse,
  makeSelectActiveUser,
  makeSelectCourses
} from "Store/user/selectors";
import { fetchStudentUnitsBySubject } from "Store/lessons/actions";
import { makeSelectStudentSubjects } from "Store/lessons/selectors";
import CoursesDropdown from "components/Shared/Navbar/Courses/CoursesDropdown/CoursesDropdown";
import { setActiveCourseId } from "utils/ActiveCourseService";
import { handleFetchSummary, handleGetTodo } from "Store/dashboard/actions";
import { fetchTodosByStudentIdApi } from "Store/dashboard/api";

const CourseSelectDropDown = () => {
  const courses = useSelector(makeSelectCourses);
  const activeCourse = useSelector(makeSelectActiveCourse);
  const subjects = useSelector(makeSelectStudentSubjects);
  const activeUser = useSelector(makeSelectActiveUser);

  const [courseOptions, setCourseOptions] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (courses) {
      const courseOpt = courses?.map((course) => ({
        label: course.course_name,
        value: course.course_id
      }));
      setCourseOptions(courseOpt);
    }
  }, []);

  useEffect(() => {
    if (courses?.length) {
      const courseOptions = courses?.map((course) => ({
        label: course.course_name,
        value: course.course_id
      }));
      setCourseOptions(courseOptions);
    }
  }, [courses]);

  useEffect(() => {
    if (subjects && activeUser) {
      const subject = subjects?.length ? subjects[0].id : null;
      dispatch(fetchStudentUnitsBySubject(subject));
    }
  }, [subjects, activeUser]);

  const onConfirmCourseSelection = (event) => {
    const selectedCourseId = event.value;
    // End function if no new course as chosen
    if (activeCourse && activeCourse.course_id === selectedCourseId) return null;

    // Dispatch action for new course
    const newActiveCourse = courses?.find((course) => course.course_id === selectedCourseId);
    dispatch(setActiveCourse(newActiveCourse));

    setActiveCourseId(newActiveCourse?.course_id);

    // Decide if I need to reload or redirect the page
    const linkOrigin = Router.router.route;

    switch (linkOrigin) {
      case "/dashboard":
        dispatch(handleGetTodo());
        return dispatch(handleFetchSummary());
      case "/worksheets":
        return dispatch(fetchWorksheets());
      case "/tests":
        return dispatch(handleFetchStudentTests());
      case "/tests/[testId]/startTest":
        return Router.push("/tests");
      case "/tests/[testId]/section/[sectionId]":
        return Router.push("/tests");
      case "/tests/[testId]":
        return Router.push("/tests");
      case "/profile":
        return null;
    }
  };
  return (
    <>
      {activeCourse && courseOptions.length > 1 && (
        <CoursesDropdown
          value={courseOptions.find((option) => option.value === activeCourse?.course_id)}
          options={courseOptions}
          label="Course Selection"
          onChange={(event) => onConfirmCourseSelection(event)}
        />
      )}
    </>
  );
};

export default CourseSelectDropDown;
