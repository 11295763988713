/* HANDLE_FETCH_TESTS_BY_STUDENT_ID */
export const HANDLE_FETCH_TESTS_BY_STUDENT_ID = "HANDLE_FETCH_TESTS_BY_STUDENT_ID";

export interface IHandleFetchTestsByStudentId {
  type: typeof HANDLE_FETCH_TESTS_BY_STUDENT_ID;
}

export const SET_STUDENT_TESTS = "SET_STUDENT_TESTS";
export interface ISetStudentTests {
  type: typeof SET_STUDENT_TESTS;
  payload: Test[];
}

export const HANDLE_FETCH_TEST_BY_TEST_ID = "HANDLE_FETCH_TEST_BY_TEST_ID";
export interface IHandleFetchTestByTestId {
  type: typeof HANDLE_FETCH_TEST_BY_TEST_ID;
  payload: string;
}

export const FETCH_TEST_BY_TEST_ID_RESOLVED = "FETCH_TEST_BY_TEST_ID_RESOLVED";
export interface IFetchTestByTestIdResolved {
  type: typeof FETCH_TEST_BY_TEST_ID_RESOLVED;
  payload: Test;
}

/* =============HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID ===========*/

export const HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID =
  "HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID";
export interface IHandleFetchStudentAnswersByStudentTestId {
  type: typeof HANDLE_FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID;
  studentTestId: string;
  sectionId: string;
}

export const FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID_REJECTED =
  "FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID_REJECTED";
export interface IFetchStudentAnswersByStudentTestIdRejected {
  type: typeof FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID_REJECTED;
  payload: Error;
}

/* =================================== */

export const HANDLE_UPDATE_STUDENT_TEST_STATUS = "HANDLE_UPDATE_STUDENT_TEST_STATUS";

export const HANDLE_UPDATE_STUDENT_TEST_SECTION_STATUS =
  "HANDLE_UPDATE_STUDENT_TEST_SECTION_STATUS";
export interface IHandleUpdateStudentTestSectionStatus {
  type: typeof HANDLE_UPDATE_STUDENT_TEST_SECTION_STATUS;
  payload: { studentTestSectionId: string; status: string; studentTestId: string };
}
export const HANDLE_ADD_STUDENT_ANSWER_TO_TEST = "HANDLE_ADD_STUDENT_ANSWER_TO_TEST";
export interface IHandleAddStudentAnswerToTest {
  type: typeof HANDLE_ADD_STUDENT_ANSWER_TO_TEST;
  payload: TestAnswerBody;
}

export const ADD_STUDENT_ANSWER_TO_TEST_REJECTED = "ADD_STUDENT_ANSWER_TO_TEST_REJECTED";
export interface IAddAnswerToTestRejected {
  type: typeof ADD_STUDENT_ANSWER_TO_TEST_REJECTED;
  payload: AnswerRejectedPayload;
}

export const START_SECTION = "START_SECTION";

export const OPEN_SECTION_REVIEW = "OPEN_SECTION_REVIEW";

export const HANDLE_OPEN_DETAIL_TEST_REVIEW = "HANDLE_OPEN_DETAIL_TEST_REVIEW";
export interface IHandleOpenDetailTestReview {
  type: typeof HANDLE_OPEN_DETAIL_TEST_REVIEW;
  payload: string;
}

export const CLOSE_DETAIL_TEST_REVIEW = "CLOSE_DETAIL_TEST_REVIEW";
export interface ICloseDetailTestReview {
  type: typeof CLOSE_DETAIL_TEST_REVIEW;
}

export const HANDLE_FETCH_STUDENT_TEST_SCORES = "HANDLE_FETCH_STUDENT_TEST_SCORES";
export interface IHandleFetchStudentTestScores {
  type: typeof HANDLE_FETCH_STUDENT_TEST_SCORES;
  studentTestId: string;
}

export const FETCH_STUDENT_TEST_SCORES_RESOLVED = "FETCH_STUDENT_TEST_SCORES_RESOLVED";
export interface IFetchStudentTestScoresResolved {
  type: typeof FETCH_STUDENT_TEST_SCORES_RESOLVED;
  payload: TestScores;
}

export const COMPLETE_STUDENT_TEST_UPDATE = "COMPLETE_STUDENT_TEST_UPDATE";
export interface ICompleteStudentTestUpdate {
  type: typeof COMPLETE_STUDENT_TEST_UPDATE;
  payload: TestsListByStatus;
}

/* ============= HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID ============== */

export const HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID =
  "HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID";
export interface IHandleFetchProblemsByStudentTestSectionId {
  type: typeof HANDLE_FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID;
  studentTestId: string;
  testSectionId: string;
}

export const SET_TEST_PROBLEMS = "SET_TEST_PROBLEMS";
export interface ISetTestProblems {
  type: typeof SET_TEST_PROBLEMS;
  payload: TestSection;
}

export const FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID_REJECTED =
  "FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID_REJECTED";
export interface IFetchProblemsByStudentTestSectionIdRejected {
  type: typeof FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID_REJECTED;
  payload: Error;
}

/* ======================== */

export const HANDLE_START_STUDENT_TEST = "HANDLE_START_STUDENT_TEST";
export interface IHandleStartStudentTest {
  type: typeof HANDLE_START_STUDENT_TEST;
  payload: Partial<Test>;
}

export const SET_STUDENT_ACTIVE_TEST = "SET_STUDENT_ACTIVE_TEST";
export interface ISetStudentActiveTest {
  type: typeof SET_STUDENT_ACTIVE_TEST;
  payload: Test;
}

export const HANDLE_START_STUDENT_TEST_SECTION = "HANDLE_START_STUDENT_TEST_SECTION";
export interface IHandleStartStudentTestSection {
  type: typeof HANDLE_START_STUDENT_TEST_SECTION;
  studentTest: Test;
  studentTestSection: TestSection;
}

export const SET_STUDENT_ACTIVE_TEST_SECTION = "SET_STUDENT_ACTIVE_TEST_SECTION";
export interface ISetStudentActiveTestSection {
  type: typeof SET_STUDENT_ACTIVE_TEST_SECTION;
  payload: TestSection;
}

export const HANDLE_COMPLETE_STUDENT_TEST_SECTION = "HANDLE_COMPLETE_STUDENT_TEST_SECTION";
export interface IHandleCompleteStudentTestSection {
  type: typeof HANDLE_COMPLETE_STUDENT_TEST_SECTION;
  studentTestSection: TestSection;
}

export const HANDLE_COMPLETE_STUDENT_TEST = "HANDLE_COMPLETE_STUDENT_TEST";
export interface IHandleCompleteStudentTest {
  type: typeof HANDLE_COMPLETE_STUDENT_TEST;
  payload: Test;
}

export const HANDLE_ADD_STUDENT_TEST_QUESTION_FLAG = "HANDLE_ADD_STUDENT_TEST_QUESTION_FLAG";
export interface IHandleAddStudentTestQuestionFlag {
  type: typeof HANDLE_ADD_STUDENT_TEST_QUESTION_FLAG;
  payload: TestFlagBody;
}

export const HANDLE_UPDATE_STUDENT_TEST_QUESTION_FLAG = "HANDLE_UPDATE_STUDENT_TEST_QUESTION_FLAG";
export interface IHandleUpdateStudentTestQuestionFlag {
  type: typeof HANDLE_UPDATE_STUDENT_TEST_QUESTION_FLAG;
  postBody: TestFlagBody;
}

export const HANDLE_FETCH_SECTION_BY_SECTION_ID = "HANDLE_FETCH_SECTION_BY_SECTION_ID";
export interface IHandleFetchSectionBySectionId {
  type: typeof HANDLE_FETCH_SECTION_BY_SECTION_ID;
  studentTestId: string;
  sectionId: string;
}

export const RESET_TEST_ERROR = "RESET_TEST_ERROR";
export const SEND_TEST_ERROR = "SEND_TEST_ERROR";
export interface ISendTestError {
  type: typeof SEND_TEST_ERROR;
  propertyName: string;
  message: string;
}

export const HANDLE_GET_SCORED_TESTS = "HANDLE_GET_SCORED_TESTS";
export interface IHandleGetScoredTests {
  type: typeof HANDLE_GET_SCORED_TESTS;
}

export const GET_SCORED_TESTS_RESOLVED = "GET_SCORED_TESTS_RESOLVED";
export interface ISetScoredTests {
  type: typeof GET_SCORED_TESTS_RESOLVED;
  payload: Test[];
}

export const GET_SCORED_TESTS_REJECTED = "GET_SCORED_TESTS_REJECTED";
export interface IGetScoredTestsRejected {
  type: typeof GET_SCORED_TESTS_REJECTED;
  payload: Error;
}

export const GET_SCORED_TESTS_PENDING = "GET_SCORED_TESTS_PENDING";
export interface IGetScoredTestsPending {
  type: typeof GET_SCORED_TESTS_PENDING;
}

export const SET_SCORED_TESTS_PAGINATION = "SET_SCORED_TESTS_PAGINATION";
export interface ISetScoredTestPagination {
  type: typeof SET_SCORED_TESTS_PAGINATION;
  payload: Partial<Pagination>;
}

export const SET_PAGINATION = "SET_PAGINATION";

export interface ISetPagination {
  type: typeof SET_PAGINATION;
  payload: Pagination;
  paginationType: TemplateType;
}

export const SET_FETCH_PROBLEMS_SPINNER = "SET_FETCH_PROBLEMS_SPINNER";
export interface ISetFetchProblemsSpinner {
  type: typeof SET_FETCH_PROBLEMS_SPINNER;
  payload: boolean;
}

export const HANDLE_START_TRIBYTE_TEST = "HANDLE_START_TRIBYTE_TEST";

export interface IHandleStartTribyteTest {
  type: typeof HANDLE_START_TRIBYTE_TEST;
  payload: {
    test: Test;
  };
}

export const HANDLE_START_BLUEBOOK_TEST = "HANDLE_START_BLUEBOOK_TEST";

export interface IHandleStartBlueBookTest {
  type: typeof HANDLE_START_BLUEBOOK_TEST;
  payload: {
    test: Test;
  };
}

export const HANDLE_SCORE_TRIBYTE_TEST = "HANDLE_SCORE_TRIBYTE_TEST";
export interface IHandleScoreTribyteTest {
  type: typeof HANDLE_SCORE_TRIBYTE_TEST;
}

export const HANDLE_SCORE_BLUEBOOK_TEST = "HANDLE_SCORE_BLUEBOOK_TEST";
export interface IHandleScoreBlueBookTest {
  type: typeof HANDLE_SCORE_BLUEBOOK_TEST;
  payload: {
    test: Test;
    subjects: {name:string, score:number}[];
    answers: {question, subject, correct, answer, status}[];
  };
}

export const HANDLE_FETCH_TRIBYTE_TEST_SECTION = "HANDLE_FETCH_TRIBYTE_TEST_SECTION";

export interface IHandleFEtchTribyteTestSection {
  type: typeof HANDLE_FETCH_TRIBYTE_TEST_SECTION;
  payload: {
    test: Test;
  };
}

export const SET_TRIBYTE_TEST_SECTION_URL = "SET_TRIBYTE_TEST_SECTION_URL";

export interface ISetTribyteTestSectionUrl {
  type: typeof SET_TRIBYTE_TEST_SECTION_URL;
  payload: {
    sectionId: string;
    url: string;
  };
}
