import Header from "layout/Header/Header";
import Main from "layout/Main/Main";
import React, { FC, ReactElement, useContext } from "react";
import { HeaderContext } from "utils/context/HeaderContext";
import SideNav from "components/Shared/SideNav/SideNav";

interface IProps {
  children: ReactElement;
}

export const LoggedInWrapper: FC<IProps> = ({ children }) => {
  const { title, icon, headerTabs, isBackButton } = useContext(HeaderContext);
  return (
    <div className="layout-wrapper">
      <SideNav />
      <Header title={title} iconName={icon} headerTabs={headerTabs} back={isBackButton} />
      <Main>{children}</Main>
    </div>
  );
};
