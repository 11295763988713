import Select from "components/Shared/Forms/Select/Select";
import React, { FC } from "react";
import { genClasses } from "utils/textHelpers";

interface IProps {
  value: SelectOption;
  label: string;
  onChange: (id: string) => void;
  options: SelectOption[];
}

const CoursesDropdown: FC<IProps> = ({ value, label, onChange, options }) => (
  <div className="course-selection">
    <p className="header">{label}</p>
    {options && (
      <Select
        selected={value?.value}
        options={options}
        onChange={(id) => onChange(id)}
        placeholder={value?.label}
        containerStyles={{ display: "flex" }}
      />
    )}
  </div>
);

export default CoursesDropdown;
