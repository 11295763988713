import * as userTypes from "./constants";

export const handleUpdateUserMessage = (
  payload: string | null
): userTypes.IHandleUpdateUserMessage => ({
  type: userTypes.HANDLE_UPDATE_USER_MESSAGE,
  payload
});

export const setActiveCourse = (activeCourse): userTypes.ISetActiveCourse => ({
  type: userTypes.SET_ACTIVE_COURSE,
  activeCourse
});

export const setUserIsLogged = (value): userTypes.ISetUserIsLogged => ({
  type: userTypes.SET_USER_IS_LOGGED,
  value
});

export const fetchActiveUser = (): userTypes.IHandleFetchActiveUser => ({
  type: userTypes.HANDLE_FETCH_ACTIVE_USER
});

export const setActiveUser = (payload: User): userTypes.IFetchActiveUserResolved => ({
  type: userTypes.SET_ACTIVE_USER,
  payload
});

export const handleUpdateStudentAvatar = (body): userTypes.IHandleUpdateStudentAvatar => ({
  type: userTypes.HANDLE_UPDATE_STUDENT_AVATAR,
  body
});

export const updateStudentAvatarResolved = (
  avatar: string
): userTypes.IUpdateStudentAvatarResolved => ({
  type: userTypes.UPDATE_STUDENT_AVATAR_RESOLVED,
  avatar
});

export const updateStudentAvatarPending = (): userTypes.IUpdateStudentAvatarPending => ({
  type: userTypes.UPDATE_STUDENT_AVATAR_PENDING
});

export const handleChangePassword = (payload): userTypes.IHandleChangePassword => ({
  type: userTypes.HANDLE_CHANGE_PASSWORD,
  payload
});

export const requestResetPassword = (payload): userTypes.IHandleRequestResetPassword => ({
  type: userTypes.HANDLE_REQUEST_RESET_PASSWORD,
  payload
});

export const handleUpdateUserErrors = (name, message): userTypes.IHandleUpdateUserErrors => ({
  type: userTypes.HANDLE_UPDATE_USER_ERRORS,
  name,
  message
});

export const handleResetUserErrors = (names): userTypes.IResetUserErrors => ({
  type: userTypes.RESET_USER_ERRORS,
  names
});

export const fetchLocation = (): userTypes.IHandleFetchLocation => ({
  type: userTypes.HANDLE_FETCH_LOCATION
});

export const setCurrentLocation = (data): userTypes.ISetLocation => ({
  type: userTypes.SET_LOCATION,
  data
});

export const updateStudentFlags = (data): userTypes.IHandleUpdateStudentFlags => ({
  type: userTypes.HANDLE_UPDATE_STUDENT_FLAGS,
  data
});

export const setError = (error): userTypes.ISetError => ({
  type: userTypes.SET_ERROR,
  error
});

/* UPDATE STUDENT ADDRESS */
export const handleUpdateStudentAddress = (payload): userTypes.IHandleUpdateStudentAddress => ({
  type: userTypes.HANDLE_UPDATE_STUDENT_ADDRESS,
  payload
});

export const updateStudentAddressResolved = (
  message: string
): userTypes.IUpdateStudentAddressResolved => ({
  type: userTypes.UPDATE_STUDENT_ADDRESS_RESOLVED,
  payload: message
});

export const updateStudentAddressRejected = (
  payload: ErrorMessage
): userTypes.IUpdateStudentAddressRejected => ({
  type: userTypes.UPDATE_STUDENT_ADDRESS_REJECTED,
  payload
});

/* UPDATE STUDENT ADDRESS --- END */

export const handleUpdateStudent = (payload): userTypes.IHandleUpdateStudent => ({
  type: userTypes.HANDLE_UPDATE_STUDENT,
  payload
});

export const handleSetCourses = (payload: Course[]): userTypes.ISetCourses => ({
  type: userTypes.SET_COURSES,
  payload
});

export const updateStudentResolved = (data, nestedSection?): userTypes.IUpdateStudentSuccess => ({
  type: userTypes.UPDATE_STUDENT_SUCCESS,
  data,
  nestedSection
});

export const handleUpdateLastVersionSeen = (
  payload: LastVersionSeenRequest
): userTypes.IHandleUpdateLastVersionSeen => ({
  type: userTypes.HANDLE_UPDATE_LAST_VERSION_SEEN,
  payload
});

export const handleLogin = (payload: LoginPayload): userTypes.IHandleLogin => ({
  type: userTypes.HANDLE_LOGIN,
  payload
});

export const handleLogout = (): userTypes.IHandleLogout => ({ type: userTypes.HANDLE_LOGOUT });
