import * as worksheetTypes from "./constants";

const initialState: IWorksheetReducer = {
  worksheets: null,
  currentWorksheet: null,
  video: { isPlaying: false, watched: 0 },
  addAnswerToWorksheetError: null,
  fromCompletedWorksheet: false
};

const handleActions = (state, action) => {
  switch (action.type) {
    case worksheetTypes.SET_WORKSHEETS:
      return {
        worksheets: action.payload
      };
    case worksheetTypes.SET_STUDENT_WORKSHEET:
      return {
        currentWorksheet: action.worksheet
      };
    case worksheetTypes.ADD_ANSWER_TO_WORKSHEET_REJECTED:
      return {
        addAnswerToWorksheetError: action.payload
      };
    case worksheetTypes.SET_FROM_COMPLETED_WORKSHEET:
      return {
        fromCompletedWorksheet: action.payload
      };
    default:
      return {};
  }
};

const worksheetsReducer = (state: IWorksheetReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default worksheetsReducer;
