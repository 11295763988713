import { put, select, takeLatest } from "redux-saga/effects";
import { setSummary, setTodo } from "./actions";
import { makeSelectActiveUser } from "Store/user/selectors";
import { fetchSummaryApi, fetchTodosByStudentIdApi } from "./api";
import { HANDLE_FETCH_SUMMARY, HANDLE_FETCH_TODO_LIST } from "./constants";

import * as globalActions from "Store/global/actions";
import { Toast } from "utils/toast";
import ERRORS from "constants/localization/error";

function* handleFetchSummarySaga() {
  yield put(globalActions.setFetchSpinner(true));
  try {
    const student = yield select(makeSelectActiveUser);
    if (!student) return false;
    const { data, error } = yield fetchSummaryApi(student?.id);
    if (error) {
      Toast.error(ERRORS.handleFetchSummarySaga, error);
    } else {
      yield put(setSummary(data.summary));
    }
    yield put(globalActions.setFetchSpinner(false));
  } catch (error) {
    Toast.error(ERRORS.handleFetchSummarySaga, error);
    yield put(globalActions.setFetchSpinner(false));
  }
}

function* handleGetTodosSaga() {
  yield put(globalActions.setFetchSpinner(true));
  try {
    const activeUser = yield select(makeSelectActiveUser);
    const { data, error } = yield fetchTodosByStudentIdApi(activeUser?.id);
    if (error) {
      Toast.error(ERRORS.handleGetTodos, error);
    } else {
      yield put(setTodo(data));
    }
    yield put(globalActions.setFetchSpinner(false));
  } catch (error) {
    yield put(globalActions.setFetchSpinner(false));
    Toast.error(ERRORS.handleGetTodos, error);
  }
}
export default function* dashboardSaga() {
  yield takeLatest(HANDLE_FETCH_SUMMARY, handleFetchSummarySaga);
  yield takeLatest(HANDLE_FETCH_TODO_LIST, handleGetTodosSaga);
}
