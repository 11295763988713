import React from "react";
import ReactDOM from "react-dom";
import { useSelector, useDispatch } from "react-redux";
import colors from "constants/colors";

import { setError } from "Store/user/actions";
import Icon from "components/Shared/Icons/Icon/Icon";
import Button from "components/Shared/Button/Button";

const ErrorModal = () => {
  const errors: string | null = useSelector((state: AppState) => state.userReducer.globalError);
  const dispatch = useDispatch();
  const onAbort = () => {
    dispatch(setError(null));
  };

  return errors
    ? ReactDOM.createPortal(
        <div className="error-modal-overlay">
          <div className="modal-container">
            <div
              className="modal-header"
              style={{
                background: colors.red
              }}
            >
              <div className="header-info">
                <Icon
                  icon="warning"
                  style={{
                    marginRight: "10px"
                  }}
                />
                <div>Issue message</div>
              </div>
              <Icon
                icon="close"
                style={{
                  cursor: "pointer"
                }}
                onClick={onAbort}
              />
            </div>
            <div className="modal-content">
              <Icon icon="dizzy" className="error-big-icon" />
              <div className="modal-content-title">Houston, We Have a Problem!</div>
              <div className="modal-content-info">{errors}</div>
            </div>
            <div className="error-modal-buttons-container">
              <Button name={"Close"} onClick={onAbort} />
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default ErrorModal;
