import * as globalTypes from "./constants";

export const setActionSpinner = (payload: boolean): globalTypes.ISetActionSpinner => ({
  type: globalTypes.SET_ACTION_SPINNER,
  payload
});
export const setFetchSpinner = (payload: boolean): globalTypes.ISetFetchSpinner => ({
  type: globalTypes.SET_FETCH_SPINNER,
  payload
});
export const setFetchDetailsSpinner = (payload: boolean): globalTypes.ISetFetchDetailsSpinner => ({
  type: globalTypes.SET_FETCH_DETAILS_SPINNER,
  payload
});
