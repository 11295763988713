import { combineReducers } from "redux";
import dashboardReducer from "Store/dashboard/reducer";
import testReducer from "Store/tests/reducer";
import userReducer from "Store/user/reducers";
import workBookLessonReducer from "Store/lessons/reducers";
import worksheetReducer from "Store/worksheet/reducers";
import globalReducer from "Store/global/reducer";

export default combineReducers({
  dashboardReducer,
  testReducer,
  userReducer,
  workBookLessonReducer,
  worksheetReducer,
  globalReducer
});
