export const makeSelectUserIsLogged = (state: AppState) => state.userReducer.isLogged;
export const makeSelectActiveUser = (state: AppState) => state.userReducer.activeUser;
export const makeSelectUserMessage = (state: AppState) => state.userReducer.message;
export const makeSelectUserErrors = (state: AppState) => state.userReducer.errors;
export const makeSelectLocation = (state: AppState) => state.userReducer.currentLocation;

export const makeSelectCourses = (state: AppState) => state.userReducer.courses;
export const makeSelectActiveCourse = (state: AppState) => state.userReducer.activeCourse;

export const makeSelectAvatarLoader = (state: AppState) => state.userReducer.avatarLoader;
