import decode from "jwt-decode";
import Router from "next/router";

export const loggedIn = () => {
  const token = getToken();
  return !!token && !isTokenExpired(token);
};

export const setToken = (token: string, expires_at: string, userType: string) => {
  // Saves user token to localStorage
  localStorage.setItem(`${userType}Token`, token);
  localStorage.setItem(`${userType}ExpiresAt`, expires_at);
};

export const getToken = () => {
  // Retrieves the user token from localStorage
  if (typeof window !== "undefined") {
    const queryParams = Router && Router.router && Router.router.query;

    if (queryParams.impersonateAvatar || queryParams.impersonateName) {
      localStorage.setItem(
        "impersonated",
        (queryParams.impersonateAvatar || queryParams.impersonateName) as string
      );
      sessionStorage.setItem("studentToken", queryParams.auth as string);
      Router.push("/dashboard").then();
    } else if (queryParams.auth) {
      setToken(queryParams.auth as string, queryParams.expires_at as string, "student");
      Router.push("/dashboard").then();
    }
    // Is running on the client
    return sessionStorage.getItem("studentToken") || localStorage.getItem("studentToken");
  }
};

export const logout = () => {
  // Clear user token from localStorage
  localStorage.removeItem("studentToken");
  localStorage.removeItem("studentExpiresAt");
  localStorage.removeItem("impersonated");
  sessionStorage.removeItem("studentToken");
};

export const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (err) {
    return false;
  }
};
