import React, { FC, ReactElement, ReactNode } from "react";

interface IProps {
  location?: TLocation;
  children: ReactNode;
}

export const LoggedOutWrapper: FC<IProps> = ({ location, children }) => {
  return (
    <div
      className="logout-wrapper"
      style={{
        backgroundImage:
          location && location.branding?.bg_image && `url(${location.branding.bg_image})`,
        backgroundColor: `${location ? location.branding?.background_color : "#181a1a"}`
      }}
    >
      {children}
    </div>
  );
};
