import React, { FC } from "react";
import Select from "react-select";
import SelectStyles from "./SelectStyles";

interface IProps {
  options: SelectOption[];
  selected: string | true | SelectOption | SelectOption[];
  onChange: (item: SelectOption) => void;
  label?: string;
  placeholder?: string;
  isClearable?: boolean;
  isDisabled?: boolean;
  id?: string;
  className?: string;
  multi?: boolean;
}

const FormSelect: FC<IProps> = ({
  options,
  selected,
  onChange,
  label,
  placeholder,
  isClearable,
  isDisabled,
  id,
  className,
  multi
}) => {
  return (
    <div className="form-select" data-tid={id}>
      <div className="select-label">{label}</div>
      <Select
        options={options}
        onChange={onChange}
        className={className}
        name="categoryOptions"
        placeholder={placeholder || label}
        styles={SelectStyles}
        menuPosition={"fixed"}
        value={selected}
        instanceId={id}
        isSearchable
        label={label}
        isClearable={isClearable}
        isDisabled={isDisabled}
        id={id}
        isMulti={multi}
      />
    </div>
  );
};

export default FormSelect;
