const colors = {
  adminRed: "#C74133",
  black: "#000000",
  blue: "#4E69B3",
  blueButton: "#0165b4",
  darkGreen: "#487f28",
  darkGrey: "#636466",
  englishScore: "#35a6af",
  fadedGreen: "#9FD18FCC",
  green: "#6AB23D",
  grey: "#999999",
  instructorGreen: "#629E3D",
  lightBlue: "#5ABDFF",
  lightBlueButton: "#24baf7",
  lightGreen: "#60B244",
  lightGrey: "#dadada",
  lightRed: "#FF0000",
  mathScore: "#4785f4",
  midGrey: "#b8b8b8",
  middleGrey: "#CCCCCC",
  orange: "#E99200",
  readingScore: "#35a6af",
  red: "#DA4E3F",
  scienceScore: "#55b24b",
  selectedLightBlue: "#F4F6FC",
  selectionBlue: "#F5F6FD",
  studentBlue: "#405A9C",
  totalScore: "#00BBFF",
  unSelectedLightGrey: "#646466",
  violet: "#AB48AF",
  white: "#FFFFFF",
  writingScore: "#55b24b",
  yellow: "#E2C400"
};

export const setContrast = (backgroundColor: string) => {
  const rgbBase = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor);
  const rgb = [parseInt(rgbBase[1], 16), parseInt(rgbBase[2], 16), parseInt(rgbBase[3], 16)];
  const brightness = Math.round((rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000);
  return brightness > 170 ? "black" : "white";
};

export const ICON_COLORS = {
  overdue: "#DA4E3F",
  completed: "#FFFFFF",
  unavailable: "#999999"
};

export const ICONS_COLORS = {
  completed: colors.lightGreen,
  started: colors.orange,
  assigned: colors.black,
  unassigned: colors.middleGrey
};
export default colors;
