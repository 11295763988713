import * as userTypes from "./constants";

const initialState: IUserReducer = {
  isLogged: false,
  activeUser: null,
  message: null,
  errors: {},
  currentLocation: {},
  globalError: null,
  courses: [],
  activeCourse: null,
  avatarLoader: false
};
const handleActions = (state, action) => {
  switch (action.type) {
    case userTypes.SET_USER_IS_LOGGED:
      return {
        isLogged: action.value
      };
    case userTypes.SET_ACTIVE_USER:
      return {
        activeUser: { ...action.payload }
      };
    case userTypes.HANDLE_UPDATE_USER_MESSAGE:
      return {
        message: action.payload
      };
    case userTypes.UPDATE_STUDENT_SUCCESS:
      return {
        activeUser: !action.nestedSection
          ? {
              ...state.activeUser,
              ...action.data
            }
          : {
              ...state.activeUser,
              [action.nestedSection]: action.data
            }
      };
    case userTypes.HANDLE_UPDATE_USER_ERRORS:
      return {
        errors: { [action.name]: action.message }
      };
    case userTypes.RESET_USER_ERRORS: {
      const messagesObj = action.names.reduce(
        (initial, message) => ({ ...initial, [message]: null }),
        {}
      );
      return {
        errors: messagesObj
      };
    }
    case userTypes.UPDATE_STUDENT_AVATAR_PENDING:
      return {
        avatarLoader: true
      };
    case userTypes.UPDATE_STUDENT_AVATAR_RESOLVED:
      return {
        activeUser: {
          ...state.activeUser,
          avatar: action.avatar
        },
        avatarLoader: false
      };
    case userTypes.SET_LOCATION:
      return {
        currentLocation: action.data
      };
    case userTypes.SET_ERROR:
      return {
        globalError: action.error,
        avatarLoader: false
      };
    case userTypes.SET_COURSES:
      return {
        courses: action.payload
      };

    case userTypes.SET_ACTIVE_COURSE: {
      return {
        activeCourse: action.activeCourse
      };
    }
    case userTypes.UPDATE_STUDENT_ADDRESS_RESOLVED:
      return { message: action.payload };
    case userTypes.UPDATE_STUDENT_ADDRESS_REJECTED:
      return {
        errors: { [action.payload.name]: action.payload.message }
      };
    default:
      return {};
  }
};

const userReducer = (state: IUserReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default userReducer;
