import Router from "next/router";
import { call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { setError } from "Store/user/actions";
import { getActiveUser } from "Store/user/saga";
import { makeSelectActiveUser } from "Store/user/selectors";
import * as workbookActions from "./actions";
import {
  addAnswerToLessonApi,
  addStudentLessonProblemFlagApi,
  addVideoWatchedTimeApi,
  completeStudentLessonSectionApi,
  fetchStudentLessonByIdApi,
  fetchStudentRoadMapApi,
  fetchStudentSectionByIdApi,
  startStudentLessonSectionApi,
  updateStudentLessonStatusApi
} from "./api";
import * as workbookTypes from "./constants";

import { fetchStudentSubjectsApi, fetchStudentUnitsBySubjectApi } from "Store/global/api";

import { setActionSpinner, setFetchDetailsSpinner, setFetchSpinner } from "Store/global/actions";
import { setStudentLesson } from "./actions";
import {
  makeSelectLessonSectionScores,
  makeSelectReturnToPage,
  makeSelectStudentLesson,
  makeSelectStudentUnits
} from "./selectors";

const getCombineSections = (sectionScores) =>
  sectionScores.reduce(
    (acc, val) => {
      if (val.scoring) {
        acc.scoring.correct_count += val.scoring.correct_count;
      }
      acc.scoring.question_count += val.lesson_problems.length;

      acc.problems = [
        ...acc.problems,
        ...val.lesson_problems?.map((problem) => ({
          ...problem,
          problem_section_id: val.id
        }))
      ];
      if (val.name === "challenge") {
        acc.sectionIds = {
          ...acc.sectionIds,
          challenge: val.id
        };
      }
      if (val.name === "practice") {
        acc.sectionIds = {
          ...acc.sectionIds,
          practice: val.id
        };
        if (val.scoring) {
          acc.scoring.grade = val.scoring.grade;
        }
      }

      return { ...acc };
    },
    {
      scoring: { correct_count: 0, question_count: 0, grade: undefined },
      problems: [],
      sectionIds: {}
    }
  );

function* handleGetStudentSubjects() {
  try {
    const { data, error } = yield fetchStudentSubjectsApi();

    if (error) {
    } else {
      yield put(workbookActions.fetchStudentSubjectsResolved(data.subjects));
    }
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleGetStudentSubjects", error);
  }
}

function* handleFetchStudentsRoadmapSaga(action: workbookTypes.IHandleFetchStudentsRoadmap) {
  yield put(setFetchSpinner(true));
  try {
    const activeStudent: User = yield select(makeSelectActiveUser);
    const { data: units } = yield fetchStudentRoadMapApi(activeStudent.id, action.payload);

    const mappedLessons = units?.map((unit) => {
      const studentLessons = unit.student_lessons;
      delete unit.student_lessons;

      const unitLessons = unit.lessons?.map((lesson) => {
        const stLes = studentLessons.filter((l) => l.lesson_id === lesson.id);
        return {
          ...lesson,
          student_lessons: stLes || null
        };
      });

      return {
        ...unit,
        lessons: unitLessons
      };
    });
    yield put(workbookActions.fetchStudentRoadmapResolved(mappedLessons));
    yield put(setFetchSpinner(false));
  } catch (error) {
    yield put(setFetchSpinner(false));
  }
}

function* handleFetchStudentUnitsBySubject(
  action: workbookTypes.IHandleFetchStudentUnitsBySubject
) {
  try {
    const activeUser: User = yield select(makeSelectActiveUser);

    const { data: studentUnitList, error } = yield fetchStudentUnitsBySubjectApi(
      activeUser?.id,
      action.payload
    );
    if (error) {
    } else {
      yield put(workbookActions.setUnits(studentUnitList));
    }
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleFetchStudentUnitsBySubject saga", error);
  }
}

function* handleStartStudentLesson(action) {
  yield put(setFetchDetailsSpinner(true));
  try {
    const { lessonId, sectionId } = action.payload;
    let lesson: StudentLesson;

    lesson = yield call(fetchStudentLessonById, {
      payload: { lessonId },
      type: "HANDLE_FETCH_LESSON"
    });

    if (!lesson) {
      yield put(setError("Something went wrong while fetching lesson."));
      yield Router.push("/lessons", undefined, {
        shallow: true
      });
    }

    const lessonStatus = yield lesson?.status;

    if (lessonStatus !== "STARTED" && lessonStatus !== "COMPLETED") {
      yield call(updateLessonStatus, lessonId, "STARTED");
      yield delay(500);
      lesson = yield call(fetchStudentLessonById, {
        payload: { lessonId },
        type: "HANDLE_FETCH_LESSON"
      });
      yield put(setStudentLesson(lesson));
    }

    switch (lesson?.type?.label) {
      case "Reading":
      case "Assigned Reading":
      case "Drill":
        yield Router.push("/lessons/[lessonId]", `/lessons/${lessonId}`, {
          shallow: true
        });
        break;

      case "Module":
      case "Workbook Lesson":
        let section: Section;
        section = lesson.sections.find((s) => s.id === sectionId);
        if (section.status === "ASSIGNED") {
          const startBody = yield {
            student_lesson_id: lessonId,
            lesson_section_id: sectionId
          };
          yield startStudentLessonSectionApi(startBody);
        }

        // Only after updating the status to at least started should we fetch for the section details
        section = yield call(fetchStudentSectionById, lessonId, sectionId);

        yield put({
          type: workbookTypes.SET_STUDENT_SECTION_BY_ID,
          payload: section
        });
        if (section.status === "COMPLETED") {
          yield Router.push("/lessons/[lessonId]", `/lessons/${lessonId}`, {
            shallow: true
          });
        } else {
          yield Router.push(
            "/lessons/[lessonId]/section/[sectionId]",
            `/lessons/${lessonId}/section/${sectionId}`,
            {
              shallow: true
            }
          );
        }

        break;
    }
  } catch (error) {
    console.warn("Error occurred in the handleStartStudentLesson saga", error);
    yield put(setError(error.message || "Something went wrong."));
  } finally {
    yield put(setFetchDetailsSpinner(false));
  }
}

function* handleCompleteStudentLesson(action: workbookTypes.IHandleCompleteStudentLesson) {
  // This function is when the lesson status for Drill or Reading lessons is ready to be changed to completed
  const lesson = action.payload;
  yield put(setActionSpinner(true));
  try {
    if (lesson.status !== "COMPLETED") {
      yield call(updateLessonStatus, lesson.id, "COMPLETED");
    }

    yield delay(1000);
    yield put(workbookActions.handleFetchLesson(lesson.id));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleCompleteStudentLesson saga", error);
  } finally {
    yield put(setActionSpinner(false));
  }
}

function* handleCompleteStudentLessonSection(
  action: workbookTypes.IHandleCompleteStudentLessonSection
) {
  // This function is when an individual section within a lesson is completed
  const { lesson, section } = action;
  yield put(setActionSpinner(true));

  try {
    if (lesson.status !== "COMPLETED") {
      const body = yield {
        student_lesson_id: lesson.id,
        lesson_section_id: section.id
      };
      yield completeStudentLessonSectionApi(body);
    }
    const updatedLesson = yield call(fetchStudentLessonById, {
      payload: { lessonId: lesson.id },
      type: "HANDLE_FETCH_LESSON"
    });

    yield put(workbookActions.setStudentLesson(updatedLesson));
    yield Router.push("/lessons/[lessonId]", `/lessons/${lesson.id}`, {
      shallow: true
    });
    yield put(setActionSpinner(false));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleCompleteStudenLessontSection saga", error);
    yield put(setActionSpinner(false));
  }
}
function* handleFetchStudentSectionScoreSaga() {
  try {
    const lesson: StudentLesson = yield select(makeSelectStudentLesson);

    const lessonType = yield lesson?.type.label || lesson?.lesson_type;
    switch (lessonType) {
      case "Reading":
      case "Assigned Reading":
      case "Drill":
        break;
      case "Module":
      case "Workbook Lesson":
        const sectionScores = [];
        for (const section of lesson.sections) {
          if (section.status === "COMPLETED") {
            const { data } = yield call(fetchStudentSectionScores, lesson.id, section);
            yield sectionScores.push(data);
          }
        }

        const combineSection = yield getCombineSections(sectionScores);
        yield put(workbookActions.handleSetLessonSectionScores(combineSection));
    }
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
  }
}

function* handleOpenLessonReview(action: workbookTypes.IHandleOpenDetailLessonReview) {
  yield put(setFetchDetailsSpinner(true));
  try {
    const lesson = yield select(makeSelectStudentLesson);
    const lessonType = yield lesson?.type.label || lesson?.lesson_type;

    // Change the lesson type based on other lesson properties. Some ACT module lessons will have problems but no sections for some reason.

    switch (lessonType) {
      case "Reading":
      case "Assigned Reading":
      case "Drill":
        break;
      case "Module":
      case "Workbook Lesson":
        const sectionScores = [];
        for (const section of lesson.sections) {
          if (section.status === "COMPLETED") {
            const { data } = yield call(fetchStudentSectionScores, lesson.id, section);
            yield sectionScores.push(data);
          }
        }

        const combineSection = yield getCombineSections(sectionScores);

        yield put(workbookActions.handleSetLessonSectionScores(combineSection));
        if (lesson.status === "COMPLETED") {
          yield Router.push("/lessons/[lessonId]", `/lessons/${lesson.id}`, {
            shallow: true
          });
        }
        break;

      default:
        const returnToPage = yield select(makeSelectReturnToPage);
        yield Router.push(`/${returnToPage}`, undefined, {
          shallow: true
        });
    }
    yield put(setFetchDetailsSpinner(false));
  } catch (error) {
    yield put(setFetchDetailsSpinner(false));
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleOpenLessonReview saga", error);
  }
}

function* handleAddStudentLessonQuestionFlag(
  action: workbookTypes.IHandleAddStudentLessonQuestionFlag
) {
  try {
    yield call(handleFetchStudentSectionScoreSaga);
    const lesson = yield call(fetchStudentLessonById, {
      payload: { lessonId: action.body.student_lesson_id },
      type: "HANDLE_FETCH_LESSON"
    });
    const sectionScores: SectionScores = yield select(makeSelectLessonSectionScores);

    if (lesson.type.label === "Workbook Lesson" && sectionScores) {
      const updatedSectionScoresProblems = yield {
        ...sectionScores,
        problems: sectionScores.problems?.map((problem) => {
          if (problem.problem.id === action.body.problem_id) {
            return {
              ...problem,
              flag_status: action.body.flag_status
            };
          }
          return problem;
        })
      };
      yield put(workbookActions.handleSetLessonSectionScores(updatedSectionScoresProblems));
    }
    if (lesson.type.label === "Drill") {
      const updatedLessonProblems = yield {
        ...lesson,
        problems: lesson.problems?.map((problem) => {
          if (problem.problem.id === action.body.problem_id) {
            return {
              ...problem,
              flag_status: action.body.flag_status
            };
          }
          return problem;
        })
      };
      yield put(workbookActions.setStudentLesson(updatedLessonProblems));
    }

    yield call(addStudentLessonProblemFlag, action.body);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the hhandleAddStudentLessonFlag saga", error);
  }
}

function* handleAddVideoWatchedTime(action: workbookTypes.IHandleAddVideoWatchedTime) {
  try {
    yield addVideoWatchedTimeApi(action.payload);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the addVideoWatchedTime func", error);
  }
}

function* handleAddAnswerToLessonSaga(action: workbookTypes.IHandleAddAnswerToLesson) {
  if (!action.payload?.problem_id) return;
  try {
    const { error } = yield addAnswerToLessonApi(action.payload);
    if (error) {
      yield put(
        workbookActions.addAnswerToLessonRejected({
          problem_id: action.payload.problem_id
        })
      );
    }
  } catch (error) {
    yield put(
      workbookActions.addAnswerToLessonRejected({
        problem_id: action.payload.problem_id
      })
    );
    console.warn("Error occurred in the handleAddAnswerToLesson saga", error);
  }
}

// //API Functions///
function* fetchStudentLessonById(action: workbookTypes.IHandleFetchLesson) {
  try {
    const { lessonId } = action.payload;
    const activeUser: User = yield getActiveUser();

    const { data: studentLesson } = yield fetchStudentLessonByIdApi(activeUser?.id, lessonId);

    yield put(workbookActions.setStudentLesson(studentLesson));
    return studentLesson;
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the fetchStudentLessonById fn", error);
  }
}
function* fetchStudentSectionById(lessonId: string, sectionId: string) {
  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    const { data: studentSection } = yield fetchStudentSectionByIdApi(
      activeUser.id,
      lessonId,
      sectionId
    );
    yield put(workbookActions.setStudentSectionById(studentSection));
    return studentSection;
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the fetchStudentSectionById fn", error);
  }
}

function* fetchStudentSectionScores(lessonId: string, section: Section) {
  try {
    const activeUser: User = yield select(makeSelectActiveUser);
    return yield fetchStudentSectionByIdApi(activeUser.id, lessonId, section.id);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the fetchStudentSectionById fn", error);
  }
}

function* updateLessonStatus(lessonId: string, status: string) {
  try {
    const body = yield {
      student_lesson_id: lessonId,
      status
    };
    yield updateStudentLessonStatusApi(body);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the updateStudentLessonStatus fn", error);
  }
}

function* updateLessonSectionStatus(
  activeLesson: StudentLesson,
  activeSection: Section,
  status: string
) {
  try {
    const studentUnits: StudentUnit[] = yield select(makeSelectStudentUnits);

    const updatedStudentUnits: Subject[] = yield studentUnits?.map((unit) => {
      if (unit.id === activeLesson.unit_id) {
        return {
          ...unit,
          lessons: unit.student_lessons?.map((lesson) => {
            if (lesson.id === activeLesson.id) {
              if (activeLesson.type.label === "Workbook Lesson") {
                return {
                  ...lesson,
                  sections: lesson.sections?.map((section) => {
                    if (section.id === activeSection.id) {
                      return {
                        ...section,
                        status
                      };
                    }
                    return section;
                  })
                };
              }

              return {
                ...lesson,
                status
              };
            }
            return lesson;
          })
        };
      }
      return unit;
    });
    yield put(workbookActions.setUnits(updatedStudentUnits));
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the updateLessonSectionStatus func", error);
  }
}

function* addStudentLessonProblemFlag(body: LessonFlagBody) {
  try {
    yield addStudentLessonProblemFlagApi(body);
  } catch (error) {
    yield put(setError(error.message ? error.message : "Something went wrong."));
    console.warn("Error occurred in the handleAddStudentLessonFlag func", error);
  }
}

export default function* workbookSaga() {
  yield takeLatest(workbookTypes.HANDLE_FETCH_STUDENT_SUBJECTS, handleGetStudentSubjects);
  yield takeLatest(workbookTypes.HANDLE_FETCH_LESSON, fetchStudentLessonById);
  yield takeLatest(
    workbookTypes.HANDLE_FETCH_STUDENT_UNITS_BY_SUBJECT,
    handleFetchStudentUnitsBySubject
  );
  yield takeLatest(workbookTypes.HANDLE_START_STUDENT_LESSON, handleStartStudentLesson);
  yield takeLatest(workbookTypes.HANDLE_COMPLETE_STUDENT_LESSON, handleCompleteStudentLesson);
  yield takeLatest(
    workbookTypes.HANDLE_COMPLETE_STUDENT_LESSON_SECTION,
    handleCompleteStudentLessonSection
  );
  yield takeLatest(workbookTypes.HANDLE_OPEN_DETAIL_LESSON_REVIEW, handleOpenLessonReview);
  yield takeEvery(workbookTypes.HANDLE_ADD_ANSWER_TO_LESSON, handleAddAnswerToLessonSaga);
  yield takeLatest(
    workbookTypes.HANDLE_ADD_STUDENT_LESSON_QUESTION_FLAG,
    handleAddStudentLessonQuestionFlag
  );
  yield takeLatest(workbookTypes.HANDLE_FETCH_STUDENTS_ROADMAP, handleFetchStudentsRoadmapSaga);
  yield takeLatest(workbookTypes.HANDLE_ADD_VIDEO_WATCHED_TIME, handleAddVideoWatchedTime);
  yield takeLatest(
    workbookTypes.HANDLE_FETCH_STUDENT_SECTION_SCORE,
    handleFetchStudentSectionScoreSaga
  );
}
