import React, { CSSProperties, FC, forwardRef, LegacyRef } from "react";

import Icon from "components/Shared/Icons/Icon/Icon";
import ButtonSpinner from "components/Shared/Spinner/ButtonSpinner/ButtonSpinner";
import { genClasses } from "utils/textHelpers";
import colors from "constants/colors";

export interface IButtonProps {
  name: string;
  onClick: (event: any) => void;
  onPressEnter?: () => void;
  disabled?: boolean;
  icon?: IconType;
  centered?: boolean;
  primary?: boolean;
  iconStyle?: CSSProperties;
  grey?: boolean;
  pulse?: boolean;
  blue?: boolean;
  fixedWidth?: boolean;
  style?: CSSProperties;
  className?: string;
  type?: "complete" | "primary-reverse" | "complete-reverse";
  ref?: any;
  isLoading?: boolean;
  id?: string;
  std?: boolean;
}

const ICON_COLORS = {
  primary: colors.white,
  "primary-reverse": colors.red,
  grey: colors.grey,
  disabled: colors.grey,
  blue: colors.white,
  complete: colors.white,
  "complete-reverse": colors.instructorGreen
};

const Button: FC<IButtonProps & { ref?: LegacyRef<HTMLButtonElement> }> = forwardRef(
  (
    {
      name,
      onClick,
      disabled,
      icon,
      centered,
      primary,
      iconStyle,
      grey,
      pulse,
      blue,
      fixedWidth,
      style,
      className,
      type,
      isLoading,
      onPressEnter,
      id,
      std
    },
    ref
  ) => {
    const handlePressKey = (e) => {
      if (e.key === "Enter") {
        onPressEnter();
      }
    };

    return (
      <button
        className={genClasses("button", {
          std: std,
          pulse: pulse,
          "centered-button": centered,
          blue: blue,
          "fixed-width": fixedWidth,
          disabled: disabled,
          "with-icon": icon && !isLoading,
          className,
          "primary-reverse": type === "primary-reverse",
          complete: type === "complete",
          "complete-reverse": type === "complete-reverse",
          primary: primary,
          "button-grey": grey
        })}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onKeyPress={handlePressKey}
        id={id}
        ref={ref}
      >
        {isLoading ? (
          <ButtonSpinner />
        ) : (
          icon && (
            <Icon
              className="button-icon"
              icon={icon}
              style={iconStyle}
              color={
                ICON_COLORS[
                  genClasses("", {
                    disabled: disabled,
                    blue: blue,
                    "primary-reverse": type === "primary-reverse",
                    primary: primary,
                    grey: grey,
                    complete: type === "complete",
                    "complete-reverse": type === "complete-reverse"
                  }).trim()
                ]
              }
            />
          )
        )}
        <span dangerouslySetInnerHTML={{ __html: name }} />
      </button>
    );
  }
);

export default Button;
