import * as testTypes from "./constants";
import { initialPagination } from "utils/initialObjects";

const initialState: ITestReducer = {
  studentActiveTest: null,
  studentActiveTestSection: null,
  studentActiveTestProblems: null,
  activeTestPage: "testPage",
  scores: null,
  studentTestProblems: [],

  activeTest: null,

  sectionOpen: false,

  confirmStudentAnswerModalOpen: false,
  isTimed: false,

  startedTest: false,
  previewTest: true,

  IsOpenStudentTestScore: false,
  errorMessages: {},
  scoredTests: null,
  tests: null,
  scoredTestsPagination: initialPagination,
  problemsLoading: false,
  scoredTestLoading: false,
  addAnswerToTestError: null
};

const handleActions = (state: ITestReducer, action) => {
  switch (action.type) {
    case testTypes.SET_STUDENT_TESTS:
      return {
        tests: action.payload
      };

    case testTypes.SET_STUDENT_ACTIVE_TEST:
    case testTypes.FETCH_TEST_BY_TEST_ID_RESOLVED:
      return {
        studentActiveTest: { ...action.payload }
      };

    case testTypes.SET_TEST_PROBLEMS:
      return {
        studentActiveTestProblems: action.payload
      };

    case testTypes.FETCH_PROBLEMS_BY_STUDENT_TEST_SECTION_ID_REJECTED:
      return {
        errorMessages: action.payload,
        problemsLoading: false
      };

    case testTypes.SET_STUDENT_ACTIVE_TEST_SECTION:
      return {
        studentActiveTestSection: action.payload
      };

    case testTypes.SET_FETCH_PROBLEMS_SPINNER:
      return {
        problemsLoading: action.payload
      };

    case testTypes.FETCH_STUDENT_ANSWERS_BY_STUDENT_TEST_ID_REJECTED: {
      return {
        errorMessages: action.payload,
        problemsLoading: false
      };
    }

    case testTypes.START_SECTION:
      return {
        activeSection: action.payload,
        isTimed: action.isTimed
      };

    case testTypes.OPEN_SECTION_REVIEW:
      return {
        activeTestPage: "sectionReviewPage",
        activeSection: action.payload
      };

    case testTypes.FETCH_STUDENT_TEST_SCORES_RESOLVED:
      return {
        scores: action.payload,
        IsOpenStudentTestScore: true
      };

    case testTypes.CLOSE_DETAIL_TEST_REVIEW:
      return {
        scores: null,
        studentTestProblems: []
      };

    case testTypes.SEND_TEST_ERROR:
      return {
        errorMessages: {
          ...state.errorMessages,
          [action.propertyName]: action.message
        }
      };

    case testTypes.RESET_TEST_ERROR: {
      const messagesObj = action.messages.reduce(
        (initial, message) => ({ ...initial, [message]: null }),
        {}
      );
      return {
        errorMessages: {
          ...state.errorMessages,
          ...messagesObj
        }
      };
    }
    case testTypes.GET_SCORED_TESTS_RESOLVED: {
      return {
        scoredTests: action.payload,
        scoredTestLoading: false
      };
    }

    case testTypes.GET_SCORED_TESTS_REJECTED: {
      return {
        errorMessages: action.payload,
        scoredTestLoading: false
      };
    }

    case testTypes.GET_SCORED_TESTS_PENDING: {
      return {
        scoredTestLoading: true
      };
    }

    case testTypes.SET_SCORED_TESTS_PAGINATION: {
      return {
        scoredTestsPagination: action.payload
      };
    }
    case testTypes.ADD_STUDENT_ANSWER_TO_TEST_REJECTED: {
      return {
        addAnswerToTestError: action.payload
      };
    }
    case testTypes.SET_PAGINATION:
      return {
        [`${action.paginationType}Pagination`]: {
          ...state[`${action.paginationType}Pagination`],
          ...action.payload
        }
      };
    case testTypes.SET_TRIBYTE_TEST_SECTION_URL:
      const { url, sectionId } = action.payload;
      const sections = state.studentActiveTest.sections.map((section) =>
        section.id === sectionId ? { ...section, url } : section
      );
      return { studentActiveTest: { ...state.studentActiveTest, sections: sections } };
    default:
      return {};
  }
};

const testReducer = (state: ITestReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default testReducer;
