import colors from "constants/colors";

const SelectStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none"
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: 20
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "2px 6px"
  }),
  control: (provided, { hasValue, isDisabled }) => ({
    ...provided,
    borderColor: `${hasValue ? colors.studentBlue : colors.lightGrey} !important`,
    backgroundColor: isDisabled ? colors.lightGrey : hasValue ? colors.selectionBlue : colors.white,
    borderWidth: isDisabled ? "0" : `${hasValue ? 2 : 1}px`,
    color: isDisabled ? colors.white : colors.studentBlue,
    minHeight: "0px",
    display: "flex",
    height: 40,
    boxShadow: "none"
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 0 0 10px",
    fontSize: 12
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.black
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 10000
  }),
  input: (provided) => ({ ...provided, padding: 0 }),
  singleValue: (provided, { hasValue }) => ({
    ...provided,
    color: hasValue ? colors.studentBlue : colors.black,
    fontSize: 12
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: colors[isSelected ? "studentBlue" : "white"],
    color: colors[isSelected ? "white" : "black"],
    cursor: "pointer",
    fontSize: 12,
    ":hover": {
      ...provided[":hover"],
      backgroundColor: colors[isSelected ? "studentBlue" : "lightGrey"],
      color: colors[isSelected ? "lightGrey" : "black"]
    }
  })
};

export default SelectStyles;
