import React, { FC, useContext, useEffect, useState } from "react";
import CourseSelectDropdown from "components/Shared/Navbar/Courses/CourseSelectDropDown/CourseSelectDropDown";
import Icon from "components/Shared/Icons/Icon/Icon";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { makeSelectLocation } from "Store/user/selectors";
import colors, { setContrast } from "constants/colors";
import HeaderTab from "components/Shared/_Atoms/HeaderTab/HeaderTab";
import { HeaderContext } from "utils/context/HeaderContext";

interface IProps {
  title: string;
  iconName: IconType;
  back?: boolean;
  headerTabs?: Tab[];
}

const Header: FC<IProps> = ({ title, back, headerTabs, iconName }) => {
  const location = useSelector(makeSelectLocation);
  const [colorScheme, setColorScheme] = useState("light");
  const router = useRouter();
  const {
    activeTab,
    disabledTab,
    handleOnSetActiveTab,
    hideCourseSelector,
    additionalInformationContainer
  } = useContext(HeaderContext);
  const tabs = headerTabs?.map((tab) => (
    <HeaderTab
      title={tab.title}
      handleSetActiveTab={handleOnSetActiveTab}
      activeTab={activeTab}
      key={tab.key}
      id={tab.key}
      disabled={disabledTab}
    />
  ));

  const handleGoBack = async (): Promise<void> => {
    await router.back();
  };

  useEffect(() => {
    if (location?.branding?.color1) {
      setColorScheme(setContrast(location?.branding?.color1));
    }
  }, [location]);

  return (
    <header
      className={`layout-header ${colorScheme || ""}`}
      style={{
        background: `linear-gradient(90deg, ${location?.branding?.color1} 0%, ${location?.branding?.color2}) 100%`
      }}
    >
      <div className="header-top">
        <div className="header-info">
          {back && (
            <Icon
              icon="arrow-square-left"
              className="header-back-btn"
              onClick={handleGoBack}
              color={colors[colorScheme === "dark" ? "black" : "white"]}
            />
          )}
          {Boolean(iconName) && <Icon icon={iconName} className="header-icon" />}
          <div
            className="header-title"
            style={{ color: colors[colorScheme === "dark" ? "black" : "white"] }}
          >
            {title}
          </div>
        </div>
        {!hideCourseSelector ? <CourseSelectDropdown /> : additionalInformationContainer}
      </div>
      {!!headerTabs?.length && <div className="header-tab-wrapper">{tabs}</div>}
    </header>
  );
};

export default Header;
