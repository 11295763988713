export const HANDLE_FETCH_WORKSHEETS = "HANDLE_FETCH_WORKSHEETS";
export const SET_WORKSHEETS = "SET_WORKSHEETS";

export const HANDLE_ADD_ANSWER_TO_WORKSHEET = "HANDLE_ADD_ANSWER_TO_WORKSHEET";
export interface IHandleAddAnswerToWorksheet {
  type: typeof HANDLE_ADD_ANSWER_TO_WORKSHEET;
  body: AddWorksheetAnswerRequest;
}

export const ADD_ANSWER_TO_WORKSHEET_REJECTED = "ADD_ANSWER_TO_WORKSHEET_REJECTED";
export interface IAddAnswerToWorksheetRejected {
  type: typeof ADD_ANSWER_TO_WORKSHEET_REJECTED;
  payload: AnswerRejectedPayload;
}

export const HANDLE_START_STUDENT_WORKSHEET = "HANDLE_START_STUDENT_WORKSHEET";

export const HANDLE_FETCH_WORKSHEET = "HANDLE_FETCH_WORKSHEET";

export const SET_STUDENT_WORKSHEET = "SET_STUDENT_WORKSHEET";
export interface ISetStudentWorkSheet {
  type: typeof SET_STUDENT_WORKSHEET;
  worksheet: Worksheet;
}

export const HANDLE_COMPLETE_STUDENT_WORKSHEET = "HANDLE_COMPLETE_STUDENT_WORKSHEET";

export const HANDLE_ADD_STUDENT_WORKSHEET_PROBLEM_FLAG =
  "HANDLE_ADD_STUDENT_WORKSHEET_PROBLEM_FLAG";

export interface IHandleFetchWorksheets {
  type: typeof HANDLE_FETCH_WORKSHEETS;
}

export interface FetchWorksheetsResolved {
  type: typeof SET_WORKSHEETS;
  payload: [];
}

export interface IHandleStartStudentWorksheet {
  type: typeof HANDLE_START_STUDENT_WORKSHEET;
  worksheet: Worksheet;
}

export interface IHandleOpenDetailWorksheetReview {
  type: typeof HANDLE_FETCH_WORKSHEET;
  payload: string;
}

export interface IHandleCompleteStudentWorksheet {
  type: typeof HANDLE_COMPLETE_STUDENT_WORKSHEET;
  worksheet: Worksheet;
}

export interface IHandleAddStudentWorksheetProblemFlag {
  type: typeof HANDLE_ADD_STUDENT_WORKSHEET_PROBLEM_FLAG;
  payload;
}

export const HANDLE_ADD_WORKSHEET_VIDEO_WATCHED_TIME = "HANDLE_ADD_WORKSHEET_VIDEO_WATCHED_TIME";
export interface IHandleAddWorksheetVideoWatchedTime {
  type: typeof HANDLE_ADD_WORKSHEET_VIDEO_WATCHED_TIME;
  payload: WorksheetVideoWatchedTimeUpdate;
}

export const SET_FROM_COMPLETED_WORKSHEET = "SET_FROM_COMPLETED_WORKSHEET";
export interface ISetFromCompletedWorksheet {
  type: typeof SET_FROM_COMPLETED_WORKSHEET;
  payload: boolean;
}
