import React, { FC } from "react";
import GlobalSpinner from "components/Shared/Spinner/GlobalSpinner/GlobalSpinner";
import { useSelector } from "react-redux";
import { makeSelectFetchDetailsSpinner } from "Store/global/selectors";

interface IProps {
  children: React.ReactElement;
}

const Main: FC<IProps> = ({ children }) => {
  const fetchDetailsSpinner = useSelector(makeSelectFetchDetailsSpinner);
  return (
    <main className="layout-main">
      {children}
      {fetchDetailsSpinner && <GlobalSpinner fetchingText="Fetching assignment details data..." />}
    </main>
  );
};

export default Main;
