import * as workbookTypes from "./constants";

const initialState: IWorkbookReducer = {
  subjects: [],
  studentUnits: [],
  studentLesson: null,
  studentSection: null,
  lessonAnswers: [],
  sectionScores: null,
  video: { isPlaying: false, watched: 0 },
  returnToPage: "lessons",
  workbookErrors: null,
  roadmap: [],
  error: "",
  addAnswerToWorkbookError: null
};

const handleActions = (state, action) => {
  switch (action.type) {
    case workbookTypes.FETCH_STUDENT_SUBJECTS_RESOLVED:
      return {
        subjects: action.payload
      };
    case workbookTypes.SET_UNITS:
      return {
        studentUnits: action.payload
      };
    case workbookTypes.SET_STUDENT_LESSON:
      return {
        studentLesson: action.payload
      };
    case workbookTypes.SET_STUDENT_SECTION_BY_ID:
      return {
        studentSection: action.payload
      };
    case workbookTypes.HANDLE_SET_LESSON_SECTION_SCORES:
      return {
        sectionScores: action.payload
      };
    case workbookTypes.SET_RETURN_PAGE:
      return {
        returnToPage: action.page
      };

    case workbookTypes.SET_WORKBOOK_ERRORS:
      return {
        workbookErrors: action.payload
      };
    case workbookTypes.FETCH_STUDENT_ROADMAP_RESOLVED:
      return {
        roadmap: action.payload
      };
    case workbookTypes.ADD_ANSWER_TO_LESSON_REJECTED:
      return {
        addAnswerToWorkbookError: action.payload
      };
    default:
      return {};
  }
};

const workbookLessonReducer = (state: IWorkbookReducer = initialState, action) => ({
  ...state,
  ...handleActions(state, action)
});

export default workbookLessonReducer;
