import * as lessonTypes from "./constants";

export const fetchStudentSubjects = (): lessonTypes.IFetchStudentSubjects => ({
  type: lessonTypes.HANDLE_FETCH_STUDENT_SUBJECTS
});

export const fetchStudentSubjectsResolved = (
  payload: Subject[]
): lessonTypes.IFetchStudentSubjectsResolved => ({
  type: lessonTypes.FETCH_STUDENT_SUBJECTS_RESOLVED,
  payload
});

// ------------------------------------------------------------------------------------------------------------ //

export const fetchStudentUnitsBySubject = (
  subject: string
): lessonTypes.IHandleFetchStudentUnitsBySubject => ({
  type: lessonTypes.HANDLE_FETCH_STUDENT_UNITS_BY_SUBJECT,
  payload: subject
});

export const setUnits = (payload: Subject[]): lessonTypes.ISetUnits => ({
  type: lessonTypes.SET_UNITS,
  payload
});
// ------------------------------------------------------------------------------------------------------------ //

export const handleStartStudentLesson = (payload): lessonTypes.IHandleStartStudentLesson => ({
  type: lessonTypes.HANDLE_START_STUDENT_LESSON,
  payload
});
// ------------------------------------------------------------------------------------------------------------ //

export const setStudentLesson = (payload: StudentLesson): lessonTypes.ISetStudentLesson => ({
  type: lessonTypes.SET_STUDENT_LESSON,
  payload
});

// ------------------------------------------------------------------------------------------------------------ //

export const handleCompleteStudentLesson = (
  payload: StudentLesson
): lessonTypes.IHandleCompleteStudentLesson => ({
  type: lessonTypes.HANDLE_COMPLETE_STUDENT_LESSON,
  payload
});

// ------------------------------------------------------------------------------------------------------------ //

export const handleCompleteStudentLessonSection = (
  lesson: StudentLesson,
  section: Section
): lessonTypes.IHandleCompleteStudentLessonSection => ({
  type: lessonTypes.HANDLE_COMPLETE_STUDENT_LESSON_SECTION,
  lesson,
  section
});
// ------------------------------------------------------------------------------------------------------------ //

/**
 * @params: subjectID
 * */
export const handleFetchStudentRoadmap = (
  payload: string
): lessonTypes.IHandleFetchStudentsRoadmap => ({
  type: lessonTypes.HANDLE_FETCH_STUDENTS_ROADMAP,
  payload
});

export const fetchStudentRoadmapResolved = (
  payload: any
): lessonTypes.IFetchStudentsRoadmapResolved => ({
  type: lessonTypes.FETCH_STUDENT_ROADMAP_RESOLVED,
  payload
});
// ------------------------------------------------------------------------------------------------------------ //

export const handleAddAnswerToLesson = (payload): lessonTypes.IHandleAddAnswerToLesson => ({
  type: lessonTypes.HANDLE_ADD_ANSWER_TO_LESSON,
  payload
});

export const addAnswerToLessonRejected = (
  payload: AnswerRejectedPayload
): lessonTypes.IAddAnswerToLessonRejected => ({
  type: lessonTypes.ADD_ANSWER_TO_LESSON_REJECTED,
  payload
});

// ------------------------------------------------------------------------------------------------------------ //
export const setStudentSectionById = (payload): lessonTypes.ISetStudentSectionById => ({
  type: lessonTypes.SET_STUDENT_SECTION_BY_ID,
  payload
});

// ------------------------------------------------------------------------------------------------------------ //

/**
 * @params: lessonId
 * */
export const handleOpenStudentLessonReview = (
  payload: string
): lessonTypes.IHandleOpenDetailLessonReview => ({
  type: lessonTypes.HANDLE_OPEN_DETAIL_LESSON_REVIEW,
  payload
});
// ------------------------------------------------------------------------------------------------------------ //

export const handleAddStudentLessonFlag = (
  body
): lessonTypes.IHandleAddStudentLessonQuestionFlag => ({
  type: lessonTypes.HANDLE_ADD_STUDENT_LESSON_QUESTION_FLAG,
  body
});
// ------------------------------------------------------------------------------------------------------------ //

export const handleSetLessonSectionScores = (
  payload
): lessonTypes.IHandleSetLessonSectionScores => ({
  type: lessonTypes.HANDLE_SET_LESSON_SECTION_SCORES,
  payload
});
// ------------------------------------------------------------------------------------------------------------ //

export const setReturnPage = (page: string): lessonTypes.ISetReturnPage => ({
  type: lessonTypes.SET_RETURN_PAGE,
  page
});
// ------------------------------------------------------------------------------------------------------------ //

export const handleAddVideoWatchedTime = (
  payload: LessonVideoWatchedTimeUpdate
): lessonTypes.IHandleAddVideoWatchedTime => ({
  type: lessonTypes.HANDLE_ADD_VIDEO_WATCHED_TIME,
  payload
});

export const handleFetchLesson = (lessonId: string): lessonTypes.IHandleFetchLesson => ({
  type: lessonTypes.HANDLE_FETCH_LESSON,
  payload: {
    lessonId
  }
});

export const handleFetchStudentSectionScore = (): lessonTypes.IHandleFetchStudentSectionScore => ({
  type: lessonTypes.HANDLE_FETCH_STUDENT_SECTION_SCORE
});
