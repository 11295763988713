export const SET_USER_IS_LOGGED = "SET_USER_IS_LOGGED";
export interface ISetUserIsLogged {
  type: typeof SET_USER_IS_LOGGED;
  value: boolean;
}

export const HANDLE_FETCH_ACTIVE_USER = "HANDLE_FETCH_ACTIVE_USER";
export interface IHandleFetchActiveUser {
  type: typeof HANDLE_FETCH_ACTIVE_USER;
}

export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export interface IFetchActiveUserResolved {
  type: typeof SET_ACTIVE_USER;
  payload: User;
}

export const HANDLE_UPDATE_USER_MESSAGE = "HANDLE_UPDATE_USER_MESSAGE";
export interface IHandleUpdateUserMessage {
  type: typeof HANDLE_UPDATE_USER_MESSAGE;
  payload: string | null;
}

export const HANDLE_UPDATE_STUDENT_AVATAR = "HANDLE_UPDATE_STUDENT_AVATAR";
export interface IHandleUpdateStudentAvatar {
  type: typeof HANDLE_UPDATE_STUDENT_AVATAR;
  body: any;
}

export const UPDATE_STUDENT_AVATAR_RESOLVED = "UPDATE_STUDENT_AVATAR_RESOLVED";
export interface IUpdateStudentAvatarResolved {
  type: typeof UPDATE_STUDENT_AVATAR_RESOLVED;
  avatar: string;
}
export const UPDATE_STUDENT_AVATAR_PENDING = "UPDATE_STUDENT_AVATAR_PENDING";
export interface IUpdateStudentAvatarPending {
  type: typeof UPDATE_STUDENT_AVATAR_PENDING;
}

export const HANDLE_CHANGE_PASSWORD = "HANDLE_CHANGE_PASSWORD";
export interface IHandleChangePassword {
  type: typeof HANDLE_CHANGE_PASSWORD;
  payload: any;
}

export const HANDLE_REQUEST_RESET_PASSWORD = "HANDLE_REQUEST_RESET_PASSWORD";
export interface IHandleRequestResetPassword {
  type: typeof HANDLE_REQUEST_RESET_PASSWORD;
  payload: { email: string };
}

export const HANDLE_UPDATE_USER_ERRORS = "HANDLE_UPDATE_USER_ERRORS";
export interface IHandleUpdateUserErrors {
  type: typeof HANDLE_UPDATE_USER_ERRORS;
  name: string;
  message: string;
}

export const RESET_USER_ERRORS = "RESET_USER_ERRORS";
export interface IResetUserErrors {
  type: typeof RESET_USER_ERRORS;
  names: string;
}

export const UPDATE_STUDENT_SUCCESS = "UPDATE_STUDENT_SUCCESS";
export interface IUpdateStudentSuccess {
  type: typeof UPDATE_STUDENT_SUCCESS;
  data: User;
  nestedSection: any;
}

export const HANDLE_FETCH_LOCATION = "HANDLE_FETCH_LOCATION";
export interface IHandleFetchLocation {
  type: typeof HANDLE_FETCH_LOCATION;
}

export const SET_LOCATION = "SET_LOCATION";
export interface ISetLocation {
  type: typeof SET_LOCATION;
  data: Location;
}

export const HANDLE_UPDATE_STUDENT_FLAGS = "HANDLE_UPDATE_STUDENT_FLAGS";
export interface IHandleUpdateStudentFlags {
  type: typeof HANDLE_UPDATE_STUDENT_FLAGS;
  data: Flag;
}

// Global Error as it seems like most fit place without creating new state folder
export const SET_ERROR = "SET_ERROR";
export interface ISetError {
  type: typeof SET_ERROR;
  error: Error;
}

// Profile
export const HANDLE_UPDATE_STUDENT = "HANDLE_UPDATE_STUDENT";
export interface IHandleUpdateStudent {
  type: typeof HANDLE_UPDATE_STUDENT;
  payload: EditUser;
}

export const HANDLE_UPDATE_STUDENT_ADDRESS = "HANDLE_UPDATE_STUDENT_ADDRESS";
export interface IHandleUpdateStudentAddress {
  type: typeof HANDLE_UPDATE_STUDENT_ADDRESS;
  payload: Address;
}

export const UPDATE_STUDENT_ADDRESS_RESOLVED = "UPDATE_STUDENT_ADDRESS_RESOLVED";
export interface IUpdateStudentAddressResolved {
  type: typeof UPDATE_STUDENT_ADDRESS_RESOLVED;
  payload: string;
}

export const UPDATE_STUDENT_ADDRESS_REJECTED = "UPDATE_STUDENT_ADDRESS_REJECTED";
export interface IUpdateStudentAddressRejected {
  type: typeof UPDATE_STUDENT_ADDRESS_REJECTED;
  payload: ErrorMessage;
}

export const SET_COURSES = "SET_COURSES";
export interface ISetCourses {
  type: typeof SET_COURSES;
  payload: Course[];
}

export const SET_ACTIVE_COURSE = "SET_ACTIVE_COURSE";
export interface ISetActiveCourse {
  type: typeof SET_ACTIVE_COURSE;
  activeCourse: Course;
}

export const HANDLE_UPDATE_LAST_VERSION_SEEN = "HANDLE_UPDATE_LAST_VERSION_SEEN";
export interface IHandleUpdateLastVersionSeen {
  type: typeof HANDLE_UPDATE_LAST_VERSION_SEEN;
  payload: LastVersionSeenRequest;
}

export const HANDLE_LOGIN = "HANDLE_LOGIN";
export interface IHandleLogin {
  type: typeof HANDLE_LOGIN;
  payload: LoginPayload;
}

export const HANDLE_LOGOUT = "HANDLE_LOGOUT";
export interface IHandleLogout {
  type: typeof HANDLE_LOGOUT;
}
