import { FC } from "react";

interface IProps {
  title: string;
  activeTab?: string;
  left?: number;
  handleSetActiveTab: (activeTab: string) => void;
  id: string;
  disabled?: boolean;
}

const HeaderTab: FC<IProps> = ({ left, activeTab, title, disabled, id, handleSetActiveTab }) => {
  const currentTab: boolean = activeTab === id;
  return (
    <div
      className={`header-tab${currentTab ? " active-tab" : ""}`}
      style={{ marginLeft: left, pointerEvents: disabled ? "none" : "auto" }}
      onClick={() => handleSetActiveTab(id)}
      id={id}
    >
      {title}
    </div>
  );
};

export default HeaderTab;
