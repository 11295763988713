export const menuItems: MenuItems[] = [
  {
    label: "Dashboard",
    value: "dashboard",
    icon: "home"
  },
  {
    label: "Workbook Lessons",
    value: "lessons",
    icon: "book"
  },
  {
    label: "Worksheets",
    value: "worksheets",
    icon: "pen"
  },
  {
    label: "Tests",
    value: "tests",
    icon: "graduationCap"
  }
];
