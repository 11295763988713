import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "styles/index.scss";
import config from "../package.json";
import Page from "components/Page";
import withReduxStore from "utils/with-redux-store";
import store from "Store/store";
import Router from "next/router";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid

const StudentSection = ({ Component, pageProps }) => {
  console.log(`%cVersion (S): ${config.version}`, "color:green;font-size:20px");

  useEffect(() => {
    if (!store.getState().userReducer.activeUser && Router.router.route !== "/reset") {
      store.dispatch({
        type: "HANDLE_FETCH_ACTIVE_USER"
      });
    }
  }, []);

  return (
    <Provider store={store}>
      <Page>
        <Component {...pageProps} />
      </Page>
    </Provider>
  );
};

export default withReduxStore(StudentSection);
