const ERROR = {
  handleAnswerError: (problemId) =>
    `One of the answers you selected may not have saved correctly. Please re-enter your answer for question # ${problemId}`,
  logoutSaga: "Something went wrong while logging user out.",
  handleFetchSummarySaga:
    "Your summary page cannot be displayed at the moment. Please try revisiting the page at a later time.",
  handleGetTodos: "Something went wrong while fetching ToDo list.",
  handleFetchTribyteTestSectionSaga: "Something went wrong while fetching url"
};

export default ERROR;
