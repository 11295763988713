import NEWS from "constants/localization/news";

export const getSlidesToDisplay = (lastVersionSeen: string) => {
  if (!NEWS.length) return [];
  return !lastVersionSeen
    ? NEWS
    : NEWS.filter((news) => isHigherVersion(news.introducedInVersion, lastVersionSeen));
};

export const isHigherVersion = (elementsVersion: string, lastVersionSeen: string) => {
  if (!elementsVersion) return false;
  const version = elementsVersion.split(".")?.map((e) => parseInt(e, 10));
  if (!lastVersionSeen) return true;
  const lastVersion = lastVersionSeen?.split(".")?.map((e) => parseInt(e, 10));

  if (version[0] > lastVersion[0]) {
    return true;
  } else if (version[0] === lastVersion[0]) {
    if (version[1] > lastVersion[1]) {
      return true;
    } else if (version[1] === lastVersion[1]) {
      if (version[2] > lastVersion[2]) {
        return true;
      }
    }
  }
  return false;
};
