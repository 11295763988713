export const makeSelectStudentTests = (state: AppState) => state.testReducer.tests;

export const makeSelectStudentActiveTest = (state: AppState) => state.testReducer.studentActiveTest;

export const makeSelectStudentActiveTestSection = (state: AppState) =>
  state.testReducer.studentActiveTestSection;

export const makeSelectStudentActiveTestProblems = (state: AppState) =>
  state.testReducer.studentActiveTestProblems;

export const makeSelectScores = (state: AppState) => state.testReducer.scores;

export const makeSelectTestErrorMessages = (state: AppState) => state.testReducer.errorMessages;
export const makeSelectScoredTests = (state: AppState) => state.testReducer.scoredTests;

export const makeSelectScoredTestsPagination = (state: AppState) =>
  state.testReducer.scoredTestsPagination;

export const makeSelectProblemsLoading = (state: AppState) => state.testReducer.problemsLoading;
export const makeSelectScoredTestLoading = (state: AppState) => state.testReducer.scoredTestLoading;
export const makeSelectTestAnswerError = (state: AppState) =>
  state.testReducer.addAnswerToTestError;
