export const deepEqual = (object1: Object, object2: Object) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if ((areObjects && !deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      return false;
    }
  }
  return true;
};
const isObject = (object: any) => {
  return object != null && typeof object === "object";
};

export const isNotEmpty = (object: object): boolean => {
  if (typeof object === "object") {
    if (Array.isArray(object)) {
      return object && object.length !== 0;
    } else {
      return object && Object.keys(object).length !== 0;
    }
  }
};

export const rewriteTheSameProperties = (obj1: {}, obj2: {}) =>
  Object.keys(obj1).reduce((a, key) => ({ ...a, [key]: obj2?.[key] }), {});

export const createObjectFromArray = (array: string[], fullObject: any) => {
  return array.reduce((o, key) => ({ ...o, [key]: fullObject[key] }), {});
};

export const removeDuplicateFromArrayObjects = (arr1, arr2) =>
  arr1.filter((itemFirst) => !arr2.find((itemSecond) => itemSecond.id === itemFirst.id));
